import React, { useState } from "react";
import { DataTable, FormSection, ListActionButton } from "@gelsenwasser/react";
import { Button, Modal } from "react-bootstrap";
import { BsPencil, BsPlus, BsTrash } from "react-icons/bs";
import { CellProps, Column } from "react-table";
import { Antrag, emptyFachkraft, Fachkraft, FachkraftStatus } from "interfaces/Application";
import EmployeeBasicForm from "domain/employees/EmployeeBasicForm";
import { useFormikContext } from "formik";
import { log } from "common/Logger";
import { formMethod } from "../../interfaces/TableListProps";

const Employees: React.VFC = () => {
  const defaultCols: Column<Fachkraft>[] = [
    { Header: "Nachname", accessor: "nachname" },
    { Header: "Vorname", accessor: "vorname" },
    {
      Header: " ",
      Cell: ({ row }: CellProps<Fachkraft, never>) => {
        return (
          <div className="d-flex justify-content-end">
            <ListActionButton icon={BsPencil} onClick={handleEdit(row.original, row.index)} />
            <ListActionButton icon={BsTrash} onClick={handleDelete(row.original, row.index)} />
          </div>
        );
      },
    },
  ];

  // hooks
  const { values, setFieldValue } = useFormikContext<Antrag>();

  // states
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [employee, setEmployee] = useState<Fachkraft>(emptyFachkraft);
  const [actionMethod, setActionMethod] = useState<formMethod>("create");
  const [curRowIdx, setCurRowIdx] = useState<number | undefined>(undefined);

  // parameter
  // queries und mutationen
  // effekte
  // daten
  // handler
  const handleNewEmployeeClick = () => {
    setCurRowIdx(undefined);
    setActionMethod("create");
    setShowDialog(true);
  };

  const handleEdit = (data: Fachkraft, rowId: number) => () => {
    setActionMethod("create");
    setCurRowIdx(rowId);
    setEmployee(data);
    setShowDialog(true);
  };

  const handleDelete = (data: Fachkraft, rowId: number) => () => {
    setActionMethod("delete");
    setCurRowIdx(rowId);
    setEmployee(data);
    setShowDialog(true);
  };

  const handleClose = (isCancel: boolean, neueFachkraft?: Fachkraft) => {
    const rowId = curRowIdx;
    setCurRowIdx(undefined);
    setShowDialog(false);
    if (isCancel) {
      return;
    }
    if (!neueFachkraft) {
      log.warn("missing neueFachkraft, but we're not in cancel mode; doing nothing");
      return;
    }

    if (rowId !== undefined) {
      if (actionMethod === "create") {
        values.fachkraefte[rowId] = neueFachkraft;
      }
      if (actionMethod === "delete") {
        values.fachkraefte.splice(rowId, 1);
      }
      setEmployee(emptyFachkraft);
      setFieldValue("fachkraefte", [...values.fachkraefte]);
      setActionMethod("create");
      return;
    }

    neueFachkraft.status = FachkraftStatus.NEUANLAGE;
    setFieldValue("fachkraefte", [...values.fachkraefte, neueFachkraft]);
  };

  return (
    <>
      <FormSection title="">
        <Button onClick={handleNewEmployeeClick}>
          <BsPlus /> Neue Fachkraft hinzufügen
        </Button>

        <DataTable data={values.fachkraefte} isLoading={false} columns={defaultCols} tablePageSize={5} />
      </FormSection>
      <Modal size="xl" show={showDialog} onHide={() => handleClose(true)} backdrop="static">
        <Modal.Header closeButton>
          <h4>Fachkraft hinzufügen</h4>
        </Modal.Header>
        <EmployeeBasicForm fachkraft={employee} onClose={handleClose} formMethod={actionMethod} />
      </Modal>
    </>
  );
};

export default Employees;
