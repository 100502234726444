import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import SimpleBreadcrumb from "../../components/SimpleBreadcrumb";
import { ActionButton, ContentBox } from "@gelsenwasser/react";
import { useHistory } from "react-router-dom";
import { useCompaniesQueries } from "../applications/applicationsQueries";
import {
  AntragsTyp,
  EditGastAntrag,
  editGastAntragSchema,
  emptyInstallationsUnternehmen,
} from "../../interfaces/Application";
import { Formik, Form as FormikForm, FormikHelpers } from "formik";
import { log } from "../../common/Logger";
import DocumentsGuest from "./DocumentsGuest";
import { BsArrowLeft } from "react-icons/bs";
import { useUpdateGastAusweisApplication } from "../applications/applicationMutations";
import { ModalLoader } from "../../components/ZahnradLoader";

const defaultEditGastAntrag: EditGastAntrag = {
  ...emptyInstallationsUnternehmen,
  installationsUnternehmenId: "",
  antragstyp: AntragsTyp.ERWEITERUNG,
  sparte: "",
  konzessionsGebietId: "",
  konzessionsGebietName: "",
};

const EditGastAusweis: React.VFC = () => {
  // hooks
  const history = useHistory();

  // states
  const [showLoader, setShowLoader] = useState(false);

  // parameter
  // queries und mutationen
  const { data: companies } = useCompaniesQueries();
  const { mutate: updateGastAusweis } = useUpdateGastAusweisApplication();

  // effekte
  // daten
  const pageTitle = "Ausweis aktualisieren";
  const firstCompany = companies?.[0];
  const initialFormValues: EditGastAntrag = firstCompany
    ? {
        ...firstCompany,
        sparte: "",
        konzessionsGebietName: "",
        konzessionsGebietId: "",
        installationsUnternehmenId: firstCompany.id,
        antragstyp: AntragsTyp.ERWEITERUNG,
      }
    : defaultEditGastAntrag;

  // handler
  const handlePreviousStep = () => {
    history.push("/");
  };

  const handleSubmit = (values: EditGastAntrag, actions: FormikHelpers<EditGastAntrag>) => {
    log.debug("submitting ausweis change");
    let formStepErrors = false;
    if (!values.ausweisGueltigkeit) {
      actions.setFieldError("ausweisGueltigkeit", "Bitte geben Sie die Gültigkeit an!");
      formStepErrors = true;
    }
    if (!values.ausweisNummer) {
      actions.setFieldError("ausweisNummer", "Bitte geben Sie die Ausweisnummer an!");
      formStepErrors = true;
    }
    if (!values.ausweisVorderseite) {
      actions.setFieldError("ausweisVorderseite", "Bitte eine Datei hochladen!");
      formStepErrors = true;
    }
    if (!values.ausweisRueckseite) {
      actions.setFieldError("ausweisRueckseite", "Bitte eine Datei hochladen!");
      formStepErrors = true;
    }

    if (!formStepErrors) {
      setShowLoader(true);
      updateGastAusweis(values, {
        onSuccess: (data) => {
          log.debug({ obj: data }, `Ausweis aktualisiert`);
          history.push("/");
        },
        onError: (err) => {
          log.error({ obj: err }, `Fehler bei Gastantrag Ausweis Aktualisierung`);
        },
        onSettled: () => {
          setShowLoader(false);
          actions.setSubmitting(false);
        },
      });
    }
  };

  return (
    <>
      <Container>
        <SimpleBreadcrumb subPageTitle={pageTitle} />
      </Container>
      <Container>
        <ContentBox title={pageTitle}>
          <Formik
            enableReinitialize
            initialValues={initialFormValues}
            validationSchema={editGastAntragSchema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit }): JSX.Element => {
              return (
                <FormikForm onSubmit={handleSubmit}>
                  <DocumentsGuest />
                  <Row>
                    <Col>
                      <ActionButton caption="Zurück" icon={BsArrowLeft} onClick={handlePreviousStep} />
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <Button type="submit">Speichern</Button>
                    </Col>
                  </Row>
                </FormikForm>
              );
            }}
          </Formik>
        </ContentBox>
      </Container>
      <ModalLoader show={showLoader}>{pageTitle} wird gespeichert...</ModalLoader>
    </>
  );
};

export default EditGastAusweis;
