import React, { useMemo, useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";

import SimpleBreadcrumb from "components/SimpleBreadcrumb";
import { ContentBox, FormSection, ListActionButton } from "@gelsenwasser/react";
import { BsArrowLeftRight, BsPencil, BsPlus, BsTrash } from "react-icons/bs";
import { useEmployeesQueries } from "./employeesQueries";
import DataTable from "components/DataTable";
import { CellProps, Column } from "react-table";
import {
  Antrag,
  AntragsTyp,
  CreateNewAntrag,
  emptyFachkraft,
  Fachkraft,
  FachkraftStatus,
} from "interfaces/Application";
import { formMethod } from "interfaces/TableListProps";
import EmployeeBasicForm from "./EmployeeBasicForm";
import { useCreateApplication } from "../applications/applicationMutations";
import { log } from "common/Logger";
import { useHistory } from "react-router-dom";
import { useCompaniesQueries, useGemeindenQueries } from "../applications/applicationsQueries";
import { ModalLoader } from "components/ZahnradLoader";

const headerEnd: React.CSSProperties = {
  textAlign: "end",
};

const AlterEmployee: React.VFC = () => {
  // hooks
  const history = useHistory();

  // states
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [actionMethod, setActionMethod] = useState<formMethod>("create");
  //TODO: anstatt state kann hier auf ref verwendet werden?!
  const [employee, setEmployee] = useState<Fachkraft>(emptyFachkraft);
  const [oldEmployee, setOldEmployee] = useState<Fachkraft>();
  const [showLoader, setShowLoader] = useState(false);

  // parameter
  // queries und mutationen
  const { data: employees, isLoading } = useEmployeesQueries();
  const { mutate: createApplication } = useCreateApplication();
  const { data: companies } = useCompaniesQueries();
  const { data: gemeinden } = useGemeindenQueries();

  // effekte
  // daten
  const defaultCols: Column<Fachkraft>[] = useMemo(
    () => [
      { Header: "Nachname", accessor: "nachname" },
      { Header: "Vorname", accessor: "vorname" },
      { Header: "Sparte", accessor: "sparte" },
      {
        Header: "Aktion",
        Cell: ({ row: { original } }: CellProps<Fachkraft, never>) => {
          return (
            <div className="d-flex justify-content-end">
              <ListActionButton icon={BsArrowLeftRight} onClick={handleReplace(original)} />
              <ListActionButton icon={BsPencil} onClick={handleEdit(original)} />
              <ListActionButton icon={BsTrash} onClick={handleDelete(original)} />
            </div>
          );
        },
      },
    ],
    []
  );

  const formTitle =
    actionMethod === "create"
      ? "Fachkraft hinzufügen"
      : actionMethod === "edit"
      ? "Fachkraft bearbeiten"
      : actionMethod === "replace"
      ? "Fachkraft ersetzen"
      : "Fachkraft löschen";

  // handler
  const submitAntrag = (antrag: Antrag) => {
    if (!companies || !gemeinden) {
      log.warn("missing company-data and gemeinden, cannot submit antrag; doing nothing");
      return;
    }

    setShowLoader(true);

    antrag.antragstyp = AntragsTyp.AENDERUNG_FACHKRAFT;
    antrag.installationsUnternehmenId = companies[0].id;
    antrag.installationsUnternehmen = companies[0];

    // nur beim hinzufügen von fachkräften wird die sparte der ersten (und einzigen) fachkraft in den antrag übernommen
    if (antrag.fachkraefte[0].sparte)
      log.debug("setting sparte of antrag to '%s' because of fachkraft", antrag.fachkraefte[0].sparte);
    switch (antrag.fachkraefte[0].sparte) {
      case "Wasser":
        antrag.sparte = "971080000";
        break;
      case "Strom":
        antrag.sparte = "971080001";
        break;
      case "Gas":
        antrag.sparte = "971080002";
        break;
    }

    createApplication(antrag, {
      onSuccess: (data) => {
        log.debug({ obj: data }, "created Antrag");
        history.push("/");
      },
      onError: (err) => {
        log.error({ obj: err }, "Error on creating Antrag");
      },
      onSettled: () => {
        setShowLoader(false);
        setShowDialog(false);
      },
    });
  };

  const handleNewEmployeeClick = () => {
    setActionMethod("create");
    setEmployee(emptyFachkraft);
    setShowDialog(true);
  };

  const handleEdit = (data: Fachkraft) => () => {
    setActionMethod("edit");
    setEmployee(data);
    setShowDialog(true);
  };

  const handleReplace = (data: Fachkraft) => () => {
    setActionMethod("replace");
    setEmployee({ ...emptyFachkraft });
    setOldEmployee(data);
    setShowDialog(true);
  };

  const handleDelete = (data: Fachkraft) => () => {
    setActionMethod("delete");
    setEmployee(data);
    setShowDialog(true);
  };

  const handleClose = (isCancel: boolean, neueFachkraft?: Fachkraft) => {
    if (isCancel) {
      setShowDialog(false);
      return;
    }
    if (!neueFachkraft) {
      setShowDialog(false);
      log.warn("missing neueFachkraft, but we're not in cancel mode; doing nothing");
      return;
    }

    const antrag = CreateNewAntrag();
    log.trace({ obj: antrag }, "preparing new antrag");

    switch (actionMethod) {
      case "create": {
        neueFachkraft.status = FachkraftStatus.NEUANLAGE;
        break;
      }
      case "edit": {
        if (neueFachkraft.id) {
          neueFachkraft.status = FachkraftStatus.AENDERUNG;
        }
        break;
      }
      case "replace": {
        neueFachkraft.status = FachkraftStatus.NACHFOLGER;
        if (oldEmployee) {
          oldEmployee.status = FachkraftStatus.DEAKTIVIERUNG;
          oldEmployee.entferntAm = neueFachkraft.entferntAm;
          neueFachkraft.entferntAm = new Date('1753-01-01');
        }
        if (neueFachkraft && oldEmployee) neueFachkraft.vorgaengerId = oldEmployee.id;
        break;
      }
      case "delete": {
        if (neueFachkraft.id) {
          neueFachkraft.status = FachkraftStatus.DEAKTIVIERUNG;
        }
        break;
      }
    }
    antrag.fachkraefte.push(neueFachkraft);
    if (oldEmployee) antrag.fachkraefte.push(oldEmployee);

    submitAntrag(antrag);
  };

  return (
    <>
      <Container>
        <SimpleBreadcrumb subPageTitle="Änderung Fachkraft" />
      </Container>
      <Container>
        <ContentBox title="Fachkräfte">
          <FormSection title="">
            <Button onClick={handleNewEmployeeClick}>
              <BsPlus /> Neue Fachkraft hinzufügen
            </Button>
            <DataTable
              getHeaderProps={(hp) => (hp.Header === "Aktion" ? { style: headerEnd } : {})}
              data={employees}
              isLoading={isLoading}
              columns={defaultCols}
              tablePageSize={25}
            />
          </FormSection>
        </ContentBox>
      </Container>
      <Modal size="xl" show={showDialog} onHide={() => handleClose(true)} backdrop="static">
        <Modal.Header closeButton>
          <h4>{formTitle}</h4>
        </Modal.Header>
        <EmployeeBasicForm
          fachkraft={employee}
          alteFachkraft={oldEmployee}
          onClose={handleClose}
          formMethod={actionMethod}
          enableSparteSelection
        />
      </Modal>
      <ModalLoader show={showLoader}>Änderung wird gespeichert...</ModalLoader>
    </>
  );
};

export default AlterEmployee;

