import { ErrorMessage } from "formik";
import React from "react";

interface FileErrorProps {
  errorFieldName: string;
}
export const FileError: React.VFC<FileErrorProps> = ({ errorFieldName }: FileErrorProps) => {
  return (
    <div
      style={{
        marginTop: "0.25rem",
        color: "#dc3545",
        fontSize: "0.8em",
      }}
    >
      <ErrorMessage name={errorFieldName} />
    </div>
  );
};
