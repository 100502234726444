import * as yup from "yup";
import { RequiredStringSchema } from "yup/lib/string";

export type ViewModes =
  | "selectform"
  | "guestdata"
  | "guestdatadocuments"
  | "guestsummary"
  | "maindata"
  | "documents"
  | "employees";

export type LicenseFormMode = "register" | "extend";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Pflichtfeld = (anzahlZeichen: number): RequiredStringSchema<string | undefined, Record<string, any>> =>
  yup.string().max(anzahlZeichen, `Nicht mehr als ${anzahlZeichen} Zeichen`).required("Pflichtfeld");

export type AntragFormProps = {
  formMode: LicenseFormMode;
  viewMode: ViewModes;
  setViewMode: React.Dispatch<React.SetStateAction<ViewModes>>;
  setShowLoader: React.Dispatch<React.SetStateAction<boolean>>;
  handlePreviousStep: () => void;
  pageTitle?: string;
  children?: React.ReactNode;
};

export interface Antrag {
  id: string;
  antragsDatum: Date;
  antragstyp: number;
  sparte: string;
  inhaber: string;
  handwerksrolleHauskammer: string;
  handwerk: string;
  teiltaetigkeit: string;
  anschriftWerkstatt: string;
  mitgliedInnung: string;
  haftplichtversicherung: string;
  andererInstallateurvertragMit: string;
  anzahlBeschaeftigte: AnzahlBeschaeftigte;
  gewerbeAngemeldetAm: Date;
  gewerbeAngemeldetIn: string;
  installationsUnternehmenId: string;
  installationsUnternehmen: InstallationsUnternehmen;
  fachkraefte: Fachkraft[];
  betriebsHaftplichtDatei?: File | "";
  gewerbeAnmeldungDatei?: File | "";
  handwerksrollenEintragungDatei?: File | "";
  status?: string;
}

export type GastAntrag = Pick<
  Antrag,
  "antragstyp" | "installationsUnternehmenId" | "sparte" | "andererInstallateurvertragMit"
> &
  Omit<
    InstallationsUnternehmen,
    "id" | "istKreishandwerkerschaft" | "typId" | "istGast" | "gemeindeId" | "gemeindeName"
  > & {
    sparteName: string;
    vorname: string;
    nachname: string;
    mobiltelefon: string;
    konzessionsGebietId: string;
    konzessionsGebietName: string;
    ausweisGueltigkeit: Date;
    ausweisNummer: string;
    ausweisVorderseite?: File | "";
    ausweisRueckseite?: File | "";
  };

export type EditGastAntrag = Partial<GastAntrag> &
  Pick<GastAntrag, "installationsUnternehmenId" | "konzessionsGebietId">;

export const AntragsTyp = {
  ERSTANMELDUNG: 971080000,
  ERWEITERUNG: 971080001,
  AENDERUNG_FACHKRAFT: 971080002,
};

export const InstallationsUnternehmenTyp = {
  INPRUEFUNG: 971080000,
  UNTERNEHMEN: 971080001,
};

export const editGastAntragSchema = yup.object().shape({
  id: yup.string().default(""),
  ausweisGueltigkeit: yup.string().default(""),
  ausweisNummer: yup.string().default(""),
  ausweisVorderseite: yup.mixed(),
  ausweisRueckseite: yup.mixed(),
  antragstyp: yup.number().default(AntragsTyp.ERWEITERUNG),
});

export const antragSchema = yup.object().shape({
  sparte: yup.string().required("Pflichtfeld"),
  inhaber: Pflichtfeld(100),
  handwerksrolleHauskammer: Pflichtfeld(100),
  handwerk: Pflichtfeld(100),
  haftplichtversicherung: Pflichtfeld(100),
  gewerbeAngemeldetAm: yup.date().required("Pflichtfeld"),
  gewerbeAngemeldetIn: Pflichtfeld(100),
  installationsUnternehmen: yup.object({
    name: Pflichtfeld(100),
    strasse: Pflichtfeld(250),
    plz: Pflichtfeld(20),
    gemeindeId: yup.string().required("Pflichtfeld").nullable(),
    telefon: Pflichtfeld(50),
    ort: yup.string(),
  }),
  anzahlBeschaeftigte: yup.object({
    angestellte: yup.number(),
    gesellen: yup.number(),
    helfer: yup.number(),
    auszubildende: yup.number(),
  }),
});

export const gastAntragSchema = yup.object().shape({
  installationsUnternehmenId: yup.string().default(""),
  antragstyp: yup.number().default(AntragsTyp.ERSTANMELDUNG),
  sparte: yup.string().default("").required("Pflichtfeld"),
  sparteName: yup.string().default(""),
  andererInstallateurvertragMit: yup.string().default("").required("Pflichtfeld"),
  inhaber: Pflichtfeld(100).default(""),
  name: Pflichtfeld(100).default(""),
  vorname: Pflichtfeld(100).default(""),
  nachname: Pflichtfeld(100).default(""),
  strasse: Pflichtfeld(250).default(""),
  plz: Pflichtfeld(20).default(""),
  telefon: Pflichtfeld(50).default(""),
  fax: yup.string().default(""),
  website: yup.string().default(""),
  ort: Pflichtfeld(250).default(""),
  email: yup.string().default(""),
  mobiltelefon: yup.string().default(""),
  ausweisGueltigkeit: yup.date().default(new Date()),
  ausweisNummer: yup.string().default(""),
  konzessionsGebietName: yup.string().required().min(1).default(""),
  konzessionsGebietId: yup
    .string()
    .default("")
    .when("konzessionsGebietName", {
      is: "",
      then: (schema) =>
        schema.required(`Pflichtfeld: Bitte wählen Sie ein Gebiet aus der vorgeschlagenen Liste. Wenn Ihr Gebiet nicht
    aufgeliestet sein sollte, wählen Sie dann bitte "Neues Konzessionsgebiet: <Ihr Gebiet>"`),
    }),
  ausweisVorderseite: yup.mixed(),
  ausweisRueckseite: yup.mixed(),
  sparten: yup.array().of(yup.number()).default(undefined),
});

export const emptyGastAntrag = gastAntragSchema.getDefault();

export const emptyAnzahlBeschaeftigte: AnzahlBeschaeftigte = {
  angestellte: 0,
  gesellen: 0,
  helfer: 0,
  auszubildende: 0,
};

export const emptyInstallationsUnternehmen: InstallationsUnternehmen = {
  id: "",
  name: "",
  inhaber: "",
  strasse: "",
  plz: "",
  gemeindeId: "",
  gemeindeName: "",
  telefon: "",
  fax: "",
  website: "",
  istKreishandwerkerschaft: false,
  ort: "",
  email: "",
  typId: 0,
  istGast: false,
  sparten: [],
  andererInstallateurvertragMit: "",
  konzessionsGebietId: "",
};

export const emptyAntrag: Antrag = {
  id: "",
  antragsDatum: new Date(),
  antragstyp: AntragsTyp.ERSTANMELDUNG,
  sparte: "",
  inhaber: "",
  handwerksrolleHauskammer: "",
  handwerk: "",
  teiltaetigkeit: "",
  anschriftWerkstatt: "",
  mitgliedInnung: "",
  haftplichtversicherung: "",
  andererInstallateurvertragMit: "",
  anzahlBeschaeftigte: { ...emptyAnzahlBeschaeftigte },
  gewerbeAngemeldetAm: new Date(),
  gewerbeAngemeldetIn: "",
  installationsUnternehmenId: "",
  installationsUnternehmen: { ...emptyInstallationsUnternehmen },
  fachkraefte: [],
  betriebsHaftplichtDatei: "",
  gewerbeAnmeldungDatei: "",
  handwerksrollenEintragungDatei: "",
};

export const CreateNewAntrag = (): Antrag => {
  const newAntrag = { ...emptyAntrag };
  // weil der Spread-Operator keine DeepCopies macht, muss hier das Array neu zugewiesen werden, sonst wird nur eine Referenz auf das Array übergeben, und Änderungen am Array wären "global"
  newAntrag.fachkraefte = [];
  return newAntrag;
};

export const FachkraftStatus = {
  NEUANLAGE: 971080000,
  AENDERUNG: 971080001,
  AKTUALISIERUNGBEFAEHIGUNG: 971080002,
  DEAKTIVIERUNG: 971080003,
  NACHFOLGER: 971080004,
};

export const emptyFachkraft: Fachkraft = {
  id: "",
  status: FachkraftStatus.NEUANLAGE,
  vorgaengerId: "",
  vorname: "",
  nachname: "",
  email: "",
  befaehigungId: "",
  erlangungbefaehigung: "",

  vorgaenger: "",
  nachfolger: "",

  fortbildungDatum: new Date(),
  entferntAm: new Date("1753-01-01"),

  sparte: "",

  befaehigungDatei: "",

  hatMeisterbrief: false,
  hatAndereQualifikation: false,
};

export interface Fachkraft {
  id: string;
  status: number;
  vorgaengerId: string;
  vorname: string;
  nachname: string;
  email: string;
  befaehigungId: string;
  erlangungbefaehigung: string;

  vorgaenger: string;
  nachfolger: string;

  fortbildungDatum: Date;
  entferntAm: Date;

  sparte: string;

  hatMeisterbrief: boolean;
  hatAndereQualifikation: boolean;

  /**
   * Base64 string einer Datei, die als Befähigung dem Unternehmen angefügt werden soll
   *
   * @type {string}
   * @memberof Fachkraft
   */
  befaehigungDatei?: File | "";
  sicherheitsDatei?: File | "";
  tgriDatei?: File | "";
  sonstigeDatei?: File | "";
}

export interface InstallationsUnternehmen {
  id: string;
  name: string;
  inhaber: string;
  strasse: string;
  plz: string;
  gemeindeId: string;
  gemeindeName: string;
  telefon: string;
  fax: string;
  website: string;
  istKreishandwerkerschaft: boolean;
  ort: string;
  email: string;
  typId: number;
  istGast: boolean;
  sparten: number[];
  andererInstallateurvertragMit?: string;
  konzessionsGebietId: string;
}

export interface AnzahlBeschaeftigte {
  angestellte: number;
  gesellen: number;
  helfer: number;
  auszubildende: number;
}

export interface AntragResponse {
  applicationid: string;
  installationsUnternehmenId: string;
}

export interface InstallationsUnternehmenResponse {
  id: string;
}
