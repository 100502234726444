import React, { useEffect } from "react";
import { ViewModes } from "../../interfaces/Application";
import { Row, Col, Card, Button } from "react-bootstrap";
import { parse } from "qs";
import { BiPlusCircle } from "react-icons/bi";

type FormSelectionProps = {
  setViewMode: React.Dispatch<React.SetStateAction<ViewModes>>;
};

const FormSelection: React.VFC<FormSelectionProps> = ({ setViewMode }) => {
  useEffect(() => {
    const cleanedSearch = window.location.search.replace("?", "");
    const parsedKeyPairs = parse(cleanedSearch);
    if (parsedKeyPairs?.viewMode === "guestdata") {
      setViewMode("guestdata");
    }
    if (parsedKeyPairs?.viewMode === "maindata") {
      setViewMode("maindata");
    }
  }, [window.location.search]);

  const handleSelection = (viewMode: ViewModes) => {
    setViewMode(viewMode);
  };
  return (
    <>
      <Row className="d-flex justify-content-center">
        <Col md={4}>
          <Card className="border-primary h-100 bg-light">
            <Card.Body className="d-flex flex-column">
              <Card.Text className="flex-grow-1 text-center">
              Wenn Sie Ihren Firmensitz im Netzgebiet der GELSENWASSER Energienetze GmbH haben, registrieren Sie sich bitte hier in unserem Installateurverzeichnis
              </Card.Text>
              <Button variant="primary" className="mb-3" onClick={() => handleSelection("maindata")}>
                <BiPlusCircle /> Ins Installateurverzeichnis aufnehmen
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="border-primary h-100 bg-light">
            <Card.Body className="d-flex flex-column">
              <Card.Text className="flex-grow-1 text-center">
              Wenn Ihr Firmensitz nicht im Netzgebiet der GELSENWASSER Energienetze GmbH liegt, können Sie sich hier als Gastinstallateur registrieren
              </Card.Text>
              <Button variant="primary" className="mb-3" onClick={() => handleSelection("guestdata")}>
                <BiPlusCircle /> Als Gastinstallateur registrieren
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default FormSelection;
