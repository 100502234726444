import React from "react";
import { CellProps, Column } from "react-table";
import DataTable from "components/DataTable";
import ConditionalWrapper from "common/ConditionalWrapper";
import { Konzession } from "interfaces/License";
import { TableListProps } from "interfaces/TableListProps";
import { useLicensesQueries } from "./licensesQueries";

const columns: Column<Konzession>[] = [
  { Header: "Typ", accessor: "sparte" },
  { Header: "Unternehmen", accessor: "werkname" },
  {
    Header: "Kontakt",
    accessor: "ansprechpartnerid",
    Cell: ({ row: { original } }: CellProps<Konzession>) => (
      <ConditionalWrapper
        condition={!!original.email}
        wrapper={(children) => <a href={"mailto:" + original.email}>{children}</a>}
      >
        {original.vorname} {original.nachnname}
      </ConditionalWrapper>
    ),
  },
];

const LicenseList: React.FC<TableListProps> = ({ pageSize = 25 }) => {
  const { data: licenses, isLoading } = useLicensesQueries();
  return (
    <>
      <h4>Konzessionen</h4>
      <DataTable data={licenses} isLoading={isLoading} columns={columns} tablePageSize={pageSize} />
    </>
  );
};

export default LicenseList;
