import { Field, useFormikContext } from "formik";
import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { FileError } from "../../components/FileError";
import { GastAntrag } from "../../interfaces/Application";
import { FormikControl, FormikDateField } from "@gelsenwasser/react";

const DocumentsGuest: React.VFC = () => {
  const { setFieldValue, values } = useFormikContext<GastAntrag>();

  const handleVorderseiteFileSelection: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.currentTarget.files && e.currentTarget.files.length > 0)
      setFieldValue("ausweisVorderseite", e.currentTarget.files[0]);
  };

  const handleRueckseiteFileSelection: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.currentTarget.files && e.currentTarget.files.length > 0)
      setFieldValue("ausweisRueckseite", e.currentTarget.files[0]);
  };

  return (
    <>
      <Row>
        <Col md={6} sm={12}>
          <FormikControl name="ausweisNummer" label="Ausweisnummer" />
        </Col>
        <Col md={6} sm={12}>
          <FormikControl
            name="ausweisGueltigkeit"
            label="Gültig bis"
            placeholder="Datum"
            type="date"
            as={Field}
            component={FormikDateField}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.File
            label="Ausweis Vorderseite"
            onChange={handleVorderseiteFileSelection}
            data-browse="Datei auswählen"
          />
          <FileError errorFieldName="ausweisVorderseite" />
        </Col>
      </Row>
      <FileNameLabel datei={values.ausweisVorderseite} />
      <Row>
        <Col>
          <Form.File
            label="Ausweis Rückseite"
            onChange={handleRueckseiteFileSelection}
            data-browse="Datei auswählen"
          />
          <FileError errorFieldName="ausweisRueckseite" />
        </Col>
      </Row>
      <FileNameLabel datei={values.ausweisRueckseite} />
    </>
  );
};

type FileNameLabelProps = {
  datei?: "" | File;
};

const FileNameLabel: React.VFC<FileNameLabelProps> = ({ datei }) => {
  return (
    <>
      {typeof datei !== "string" && datei !== undefined && (
        <Row>
          <Col>
            <span>
              Gelieferte Datei: <strong>&apos;{datei.name}&apos;</strong>
            </span>
          </Col>
        </Row>
      )}
    </>
  );
};

export default DocumentsGuest;
