import React from "react";

interface Props {
  color: string;
}

const AlertTriangle: React.VFC<Props> = ({ color }: Props) => {
  return (
    <div
      style={{
        marginTop: "-0.75rem",
        borderLeft: "20px solid transparent",
        borderRight: `20px solid ${color}`,
        borderBottom: "20px solid transparent",
        height: "0",
        width: "0",
        position: "absolute",
        right: "0px",
        zIndex: 2,
      }}
    />
  );
};

export default AlertTriangle;
