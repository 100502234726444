import { AxiosError } from "axios";
import { parseISO } from "date-fns";
import { useContext } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { log } from "common/Logger";
import { useErrorLogger } from "common/LoggerHooks";
import { Antrag, GastAntrag, InstallationsUnternehmen } from "interfaces/Application";
import { ExpertSkill } from "interfaces/ExpertSkill";
import { Gemeinde, Konzessionsgebiet, Sparte } from "interfaces/Municipal";
import { ApiContext } from "services/ApiContext";

const defaultLookupStaleTime = 15 * 60 * 1000; /* 15 Minuten, Default: 0 Minuten */
const defaultLookupCacheTime = 5 * 60 * 1000; /* Default: 5 Minuten */

export const useApplicationsQueries = (): UseQueryResult<Antrag[], AxiosError> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);

  const GetApplications = async (): Promise<Antrag[]> => {
    const response = await api.get("/antraege");
    log.trace({ obj: response.data }, "result from get applications");
    return response.data.map((d) => {
      d["gewerbeAngemeldetAm"] = d["gewerbeAngemeldetAm"] ? parseISO(d["gewerbeAngemeldetAm"]) : new Date();
      d["antragsDatum"] = d["antragsDatum"] ? parseISO(d["antragsDatum"]) : new Date();
      return d;
    });
  };
  return useQuery<Antrag[], AxiosError>({
    queryKey: "Applications",
    queryFn: GetApplications,
    onError: (err) => logAxiosError("Error loading Anträge", "Fehler beim Laden der Anträge", err),
  });
};

export const useGuestApplicationsQueries = (): UseQueryResult<GastAntrag[], AxiosError> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);

  const GetGuestApplications = async (): Promise<GastAntrag[]> => {
    const response = await api.get("/gastantraege");
    log.trace({ obj: response.data }, "result from get Guest applications");
    return response.data.map((d) => {
      d["ausweisGueltigkeit"] = d["ausweisGueltigkeit"] ? parseISO(d["ausweisGueltigkeit"]) : new Date();
      return d;
    });
  };
  return useQuery<GastAntrag[], AxiosError>({
    queryKey: "GuestApplications",
    queryFn: GetGuestApplications,
    onError: (err) => logAxiosError("Error loading GuestAnträge", "Fehler beim Laden der GuestAnträge", err),
  });
};

export const useSkillsQueries = (): UseQueryResult<ExpertSkill[], AxiosError> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);

  const GetExpertSkills = async (): Promise<ExpertSkill[]> => {
    const response = await api.get<ExpertSkill[]>("/fachmannbefaehigungen");
    log.trace({ obj: response.data }, "result from get ExpertSkills");
    return response.data;
  };
  return useQuery<ExpertSkill[], AxiosError>({
    queryKey: "ExpertSkill",
    queryFn: GetExpertSkills,
    staleTime: defaultLookupStaleTime,
    cacheTime: defaultLookupCacheTime,
    onError: (err) => logAxiosError("Error loading Befähigungen", "Fehler beim Laden der Befähigungen", err),
  });
};

export const useKonzessionsgebieteQueries = (): UseQueryResult<Konzessionsgebiet[], AxiosError> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);

  const GetKonzessionsgebiete = async (): Promise<Konzessionsgebiet[]> => {
    const response = await api.get<Konzessionsgebiet[]>("/konzessionen/gebiete");
    log.trace({ obj: response.data }, "result from get konzessionsgebiete");
    return response.data;
  };
  return useQuery<Konzessionsgebiet[], AxiosError>({
    queryKey: "Konzessionsgebiet",
    queryFn: GetKonzessionsgebiete,
    staleTime: defaultLookupStaleTime,
    cacheTime: defaultLookupCacheTime,
    onError: (err) =>
      logAxiosError("Error loading Konzessionsgebiete", "Fehler beim Laden der Konzessionsgebiete", err),
  });
};

export const useGemeindenQueries = (): UseQueryResult<Gemeinde[], AxiosError> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);

  const GetGemeinden = async (): Promise<Gemeinde[]> => {
    const response = await api.get<Gemeinde[]>("/gemeinden");
    log.trace({ obj: response.data }, "result from get Gemeinden");
    return response.data;
  };
  return useQuery<Gemeinde[], AxiosError>({
    queryKey: "Gemeinden",
    queryFn: GetGemeinden,
    staleTime: defaultLookupStaleTime,
    cacheTime: defaultLookupCacheTime,
    onError: (err) => logAxiosError("Error loading Gemeinden", "Fehler beim Laden der Gemeinden", err),
  });
};

export const useCompaniesQueries = (): UseQueryResult<InstallationsUnternehmen[], AxiosError> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);

  const GetCompanies = async (): Promise<InstallationsUnternehmen[]> => {
    const response = await api.get<InstallationsUnternehmen[]>("/installationsunternehmen");
    log.trace({ obj: response.data }, "result from get Companies");
    return response.data;
  };
  return useQuery<InstallationsUnternehmen[], AxiosError>({
    queryKey: "Companies",
    queryFn: GetCompanies,
    staleTime: defaultLookupStaleTime,
    cacheTime: defaultLookupCacheTime,
    onError: (err) =>
      logAxiosError("Error loading installationsunternehmen", "Fehler beim Laden der installationsunternehmen", err),
  });
};

export const useSpartenQueries = (): UseQueryResult<Sparte[], AxiosError> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);

  const GetSparten = async (): Promise<Sparte[]> => {
    const response = await api.get<{ value: Array<Sparte> }>("/sparten");
    log.trace({ obj: response.data }, "result from get sparten");
    return response.data.value;
  };
  return useQuery<Sparte[], AxiosError>({
    queryKey: "Sparten",
    queryFn: GetSparten,
    staleTime: defaultLookupStaleTime,
    cacheTime: defaultLookupCacheTime,
    onError: (err) => logAxiosError("Error loading Sparten", "Fehler beim Laden der Sparten", err),
  });
};
