import { AxiosError } from "axios";

import { log } from "./Logger";
import { useNotification, NotificationType } from "@gelsenwasser/react";

interface logAxiosErrorReturn {
  logAxiosError: (internalMessage: string, externalMessage: string, error: AxiosError) => void;
}

export const useErrorLogger = (): logAxiosErrorReturn => {
  const { addNotification } = useNotification();

  const logAxiosError = (internalMessage: string, externalMessage: string, error: AxiosError) => {
    log.error(error, internalMessage);
    addNotification(
      error.isAxiosError && error.response?.data?.detail ? error.response.data.detail : error.message,
      externalMessage,
      NotificationType.Alert
    );
  };

  return { logAxiosError };
};
