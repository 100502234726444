import React, { useEffect, useState } from "react";
import { Alert, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import { ActionButton } from "@gelsenwasser/react";

import { IDENTITY_CONFIG } from "services/authentication/AuthConst";
import AuthService from "services/authentication/AuthService";
import { log } from "common/Logger";

const myBasePath = document.location.protocol + "//" + document.location.host;
const RegisterIdentityConfig = {
  ...IDENTITY_CONFIG,
  authority: `https://${process.env.REACT_APP_TENANT_NAME}.b2clogin.com/${process.env.REACT_APP_TENANT_NAME}.onmicrosoft.com/${process.env.REACT_APP_REGISTER_POLICY_NAME}`,
  metadataUrl: `https://${process.env.REACT_APP_TENANT_NAME}.b2clogin.com/${process.env.REACT_APP_TENANT_NAME}.onmicrosoft.com/${process.env.REACT_APP_REGISTER_POLICY_NAME}/v2.0/.well-known/openid-configuration`,
  redirect_uri: myBasePath + "/signinregister-oidc",
};
const registerAuthService = new AuthService(RegisterIdentityConfig);
const aadErrRegex = /AADB2C[\d]{5,5}:(.*)$/gm;

export const RegisterGuest: React.VFC = () => {
  registerAuthService.signinRedirect();

  log.debug("starting registration-only flow from b2c");

  return (
    <div>
      <Spinner animation="border" />
      &nbsp;Starte Registrierung ...
    </div>
  );
};

export const LogInRegisterCallback: React.FC = () => {
  // hooks
  const history = useHistory();

  // states
  const [loadingState, setLoadingState] = useState<"loading" | "success" | "error">("loading");
  const [errorMessage, setErrorMessage] = useState("Es ist ein Fehler bei der Anmeldung passiert!");

  // parameter
  // queries und mutationen
  // effekte
  useEffect(() => {
    registerAuthService
      .signinRedirectCallback()
      .then((_user) => {
        setLoadingState("success");
      })
      .catch((reason) => {
        log.error({ obj: reason }, "Error while processing callback from loginregister-redirect");
        const match = aadErrRegex.exec(reason);
        if (match !== null && match.length === 2) {
          setErrorMessage(match[1].trim());
        }
        setLoadingState("error");
      });
    log.debug("callback after loginregister from register-flow; will redirect to /register page, when successfull");
  }, []);

  // daten
  // handler
  const handleGotoStart = () => {
    history.push("/");
  };

  if (loadingState === "loading")
    return (
      <div className="d-flex align-items-center justify-content-center h-100">
        <Spinner animation="border" role="status" variant="primary" />
        &nbsp;Sie wurden registriert, verarbeite Anmelde-Daten ...
      </div>
    );
  if (loadingState === "success") {
    history.push("/register");
    return <></>;
  } else
    return (
      <div className="d-flex flex-column align-items-center justify-content-center h-100">
        <Alert variant="danger">{errorMessage}</Alert>
        <ActionButton onClick={handleGotoStart} caption="Zurück zur Startseite" />
      </div>
    );
};
