import { AxiosError } from "axios";
import { parseISO } from "date-fns";
import { useContext } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { log } from "common/Logger";
import { useErrorLogger } from "common/LoggerHooks";
import { Fachkraft } from "interfaces/Application";
import { ApiContext } from "services/ApiContext";

export const useEmployeesQueries = (): UseQueryResult<Fachkraft[], AxiosError> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);

  const GetEmployees = async (): Promise<Fachkraft[]> => {
    const response = await api.get("/fachkraefte");
    log.trace({ obj: response.data }, "result from get employees");
    return response.data.map((e) => {
      e["erlangungbefaehigung"] = e["erlangungbefaehigung"] ? parseISO(e["erlangungbefaehigung"]) : new Date();
      return e;
    });
  };
  return useQuery<Fachkraft[], AxiosError>({
    queryKey: "Employees",
    queryFn: GetEmployees,
    onError: (err) => logAxiosError("Error loading Fachkräfte", "Fehler beim Laden der Fachkräfte", err),
  });
};
