import { format } from "date-fns";
import React, { ReactElement, useMemo, Fragment } from "react";
import { Table, Spinner, Pagination, Container } from "react-bootstrap";
import { useTable, Column, usePagination, Cell, Row, HeaderGroup } from "react-table";

import styles from "./DataTable.module.scss";

/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

interface Props<T extends object> {
  data?: Array<T>;
  isLoading: boolean;
  columns: Array<Column<T>>;
  tablePageSize?: number;
  displayHeaders?: boolean;
  getCellProps?: (p: Cell<T, any>) => any;
  getRowProps?: (p: Row<T>) => any;
  getHeaderProps?: (p: HeaderGroup<T>) => any;
  getColumnProps?: (p: Column<T>) => any;
}

// Create a default prop getter
const defaultPropGetter = () => ({});

interface DateCellProps {
  value: Date;
}

export const DateCell: React.VFC<DateCellProps> = ({ value }: DateCellProps) => {
  return value ? <>{format(value, "dd.MM.yyyy")}</> : null;
};

export default function DataTable<T extends object>({
  data,
  isLoading,
  columns,
  tablePageSize,
  displayHeaders = true,
  getCellProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
}: Props<T>): ReactElement {
  const myData = useMemo(() => data ?? [], [data]);
  const myColumns = useMemo(() => columns, [columns]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    page,
    prepareRow,
    //paging
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    // setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: myColumns,
      data: myData,
      initialState: { pageIndex: 0, pageSize: tablePageSize ?? 10 },
    },
    usePagination
  );

  // Loading & No-Data
  if (isLoading)
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" />
      </div>
    );

  if (!isLoading && data?.length === 0) return <Container className="mt-0">Keine Daten vorhanden!</Container>;

  if (data && data.length > 0) {
    // Paging
    const pagingItem: Array<ReactElement> = [];
    // do we need paging at all?
    if (data.length > pageSize) {
      // we want to have 2 direct page-links on either side
      const startIndex = Math.max(pageIndex - 2, 0);
      const stopIndex = Math.min(pageIndex + 3, pageOptions.length);
      // if the left-most page-link is greater than 0, we need to add ellipsis
      if (startIndex > 0) pagingItem.push(<Pagination.Ellipsis key="page-less" disabled />);
      // add page-links
      for (let index = startIndex; index < stopIndex; index++) {
        pagingItem.push(
          <Pagination.Item key={`page-${index}`} active={index === pageIndex} onClick={() => gotoPage(index)}>
            {index + 1}
          </Pagination.Item>
        );
      }
      // if the right-most page-link is less than the number of pages, we need to add ellipsis
      if (stopIndex < pageOptions.length) pagingItem.push(<Pagination.Ellipsis key="page-more" disabled />);
    }

    return (
      // we need to disable some eslint-rules, becauses are being added by get[...]Props-functions
      <Fragment>
        <Table {...getTableProps({ className: "mt-3 " + styles.table + " table-striped" })} borderless size="sm" hover>
          {displayHeaders && (
            <thead>
              {headerGroups.map((headerGroup) => (
                // eslint-disable-next-line react/jsx-key
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((headerColumn) => (
                    // eslint-disable-next-line react/jsx-key
                    <th {...headerColumn.getHeaderProps([getColumnProps(headerColumn), getHeaderProps(headerColumn)])}>
                      {headerColumn.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
          )}
          <tbody className="shadow" {...getTableBodyProps()}>
            {
              // Loop over the table rows of the current page
              page.map((row) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  // eslint-disable-next-line react/jsx-key
                  <tr {...row.getRowProps(getRowProps(row))}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <td {...cell.getCellProps([getColumnProps(cell.column), getCellProps(cell)])}>
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </Table>
        {pagingItem.length > 0 && (
          <Pagination>
            <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
            <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />

            {pagingItem.map((index) => index)}

            <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
            <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
          </Pagination>
        )}
      </Fragment>
    );
  }

  return <></>;
}
