import { FormikControl, FormikTypeahead, FormSection } from "@gelsenwasser/react";
import { log } from "common/Logger";
import { useKonzessionsgebieteQueries, useSpartenQueries } from "domain/applications/applicationsQueries";
import { useFormikContext } from "formik";
import { GastAntrag, LicenseFormMode } from "interfaces/Application";
import { Konzessionsgebiet } from "interfaces/Municipal";
import React, { useContext, useEffect } from "react";
import { Alert, Col, Row, Spinner } from "react-bootstrap";
import { AuthContext } from "services/authentication";

interface MainDataGuestProps {
  formMode: LicenseFormMode;
  disableEditCompanyName?: boolean;
}

const MainDataGuest: React.VFC<MainDataGuestProps> = ({ formMode, disableEditCompanyName }: MainDataGuestProps) => {
  // hooks
  const { values, setFieldValue } = useFormikContext<GastAntrag>();
  const authCtx = useContext(AuthContext);

  // states
  // parameter
  // queries und mutationen
  const { data: konzessionsgebiete, isLoading: isGebieteLoading } = useKonzessionsgebieteQueries();
  const { data: sparten, isLoading: isLoadingSparten } = useSpartenQueries();

  // effekte
  useEffect(() => {
    if (!konzessionsgebiete || konzessionsgebiete.length === 0) return;

    const foundGebiet = konzessionsgebiete.find((k) => k.id === values.konzessionsGebietId);
    if (foundGebiet) {
      setFieldValue("konzessionsGebietName", foundGebiet.name);
      setFieldValue("konzessionsGebietId", foundGebiet.id);
    }
  }, [konzessionsgebiete]);

  useEffect(() => {
    authCtx.getUser().then((usr) => setFieldValue("email", usr?.profile.emails[0]));
  }, []);

  // daten
  const isExtend = formMode === "extend";

  // handler
  const handleSparteChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    const value = e.target.value;
    setFieldValue("sparte", value);
  };
  const handleKonzessionsgebietChange = (gebiete: Konzessionsgebiet[]) => {
    if (gebiete.length > 0) {
      const firstKonzessionsgebiet = gebiete[0];
      const isNewEntry = konzessionsgebiete?.find((kg) => kg.id === firstKonzessionsgebiet.id);
      if (!isNewEntry) konzessionsgebiete?.push(firstKonzessionsgebiet);
      setFieldValue("konzessionsGebietId", firstKonzessionsgebiet.id);
      setFieldValue("konzessionsGebietName", firstKonzessionsgebiet.name);
    } else {
      log.debug("no konzessionsgebiet selected, clearing dependend data");
      setFieldValue("konzessionsGebietId", "");
      setFieldValue("konzessionsGebietName", "");
    }
  };

  return (
    <>
      <FormSection title="Konzession">
        <Row>
          <Col md={6} sm={12}>
            {isLoadingSparten ? (
              <>
                <Spinner animation="border" />
                &nbsp;Lade Sparten ...
              </>
            ) : (
              <>
                <FormikControl
                  label="Sparte"
                  name="sparte"
                  as="select"
                  onChange={handleSparteChange}
                  disabled={!sparten || sparten.length === 0}
                >
                  <option key="sparte_select" hidden={!!values.sparte} value="">
                    (Bitte wählen)
                  </option>
                  {sparten &&
                    sparten.map((s) => (
                      <option key={`sparte_${s.id}`} value={s.id}>
                        {s.name}
                      </option>
                    ))}
                </FormikControl>
                <Alert variant="warning" hidden={!!sparten && sparten.length > 0}>
                  Keine Sparten verfügbar!
                </Alert>
              </>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <FormikControl
              name="andererInstallateurvertragMit"
              label="Ein anderer Installateurvertrag wurde bereits geschlossen mit"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormikTypeahead
              name="konzessionsGebietId"
              label="Konzessionsgebiet"
              options={
                konzessionsgebiete
                  ?.sort((a, b) => {
                    if (a.name > b.name) return 1;
                    if (a.name < b.name) return -1;
                    return 0;
                  })
                  .filter((g) => g.name) ?? []
              }
              idField="id"
              showClear
              isLoading={isGebieteLoading}
              disabled={isExtend}
              labelKey="name"
              onChange={handleKonzessionsgebietChange}
              allowNew
              defaultSelected={konzessionsgebiete?.filter((k) => k.id === values.konzessionsGebietId)}
              newSelectionPrefix="Neues Konzessionsgebiet: "
            />
          </Col>
        </Row>
      </FormSection>
      <FormSection title="Stammdaten">
        {isExtend && (
          <Alert variant="info">
            Die Adressdaten können nicht im Rahmen einer Erweiterung der Konzession geändert werden. Um die Adressdaten
            zu ändern verwenden Sie bitte die Änderung Stammdaten von der Startseite.
          </Alert>
        )}
        <Row>
          <Col>
            <FormikControl name="inhaber" label="Inhaber" disabled={isExtend} />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormikControl name="name" label="Firmenname" disabled={isExtend || disableEditCompanyName} />
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <FormikControl name="vorname" label="Vorname" disabled={isExtend} />
          </Col>
          <Col md={6} sm={12}>
            <FormikControl name="nachname" label="Nachname" disabled={isExtend} />
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <FormikControl name="telefon" label="Telefon" disabled={isExtend} />
          </Col>
          <Col md={6} sm={12}>
            <FormikControl name="fax" label="Telefax" disabled={isExtend} />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormikControl name="mobiltelefon" label="Mobil" disabled={isExtend} />
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <FormikControl name="email" label="E-Mail" disabled />
          </Col>
          <Col md={6} sm={12}>
            <FormikControl name="website" label="Internet" disabled={isExtend} />
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <FormikControl name="strasse" label="Straße, Hausnr." disabled={isExtend} />
          </Col>
          <Col md={2} sm={12}>
            <FormikControl name="plz" label="PLZ" disabled={isExtend} />
          </Col>
          <Col md={4} sm={12}>
            <FormikControl label="Ort" name="ort" />
          </Col>
        </Row>
      </FormSection>
    </>
  );
};

export default MainDataGuest;
