import { AxiosError, AxiosResponse } from "axios";
import { useContext } from "react";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { log } from "common/Logger";
import { useErrorLogger } from "common/LoggerHooks";
import { Antrag, AntragResponse, AntragsTyp, EditGastAntrag, GastAntrag } from "interfaces/Application";
import { ApiContext } from "services/ApiContext";
import { format } from "date-fns";

enum FileType {
  Meisterbrief = "Meisterbrief",
  Sicherheit = "Sicherheit",
  TGRI = "TGRI",
  Sonstige = "Sonstige",
}

enum ApplicationFileType {
  Betriebshaftplicht = "Betriebshaftplicht",
  Gewerbeanmeldung = "Gewerbeanmeldung",
  Handwerksrolleneintragung = "Handwerksrolleneintragung",
  AusweisVorderSeite = "AusweisVorderseite",
  AusweisRueckseite = "AusweisRueckseite",
}

export const useCreateApplication = (): UseMutationResult<string, AxiosError, Antrag> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);
  const queryClient = useQueryClient();
  const { mutateAsync: uploadDocument } = useUploadDokument();

  const CreateApplication = async (data: Antrag) => {
    log.trace({ obj: data }, "creating new Antrag");

    const response = await api.post<Antrag, AxiosResponse<AntragResponse>>("/antraege", data);
    log.trace({ obj: response }, "result from post application");
    const ApplicationId = response.data.applicationid;

    const doUploadFile = (subject: string, datei: File, data: Antrag) => {
      return uploadDocument({
        unternehmenId: !data.installationsUnternehmenId
          ? response.data.installationsUnternehmenId
          : data.installationsUnternehmenId,
        datei: datei,
        subject: subject,
      });
    };

    // wenn bei den Fachkräften Dateien angefügt waren, dann werden die zum jeweiligen Unternehmen hochgeladen
    // Subject sollte folgendermaßen aussehen für ein Fachkraft Dokument. "data.fachkraftName:Upload_Dokument_Typ"
    const uploadActions: Promise<string>[] = [];
    data.fachkraefte.forEach((item) => {
      const fachkraftName = `${item.vorname} ${item.nachname}`;
      if (item.befaehigungDatei)
        uploadActions.push(doUploadFile(`${fachkraftName}:${FileType.Meisterbrief}`, item.befaehigungDatei, data));

      if (item.sicherheitsDatei)
        uploadActions.push(doUploadFile(`${fachkraftName}:Sicherheit`, item.sicherheitsDatei, data));

      if (item.tgriDatei) uploadActions.push(doUploadFile(`${fachkraftName}:TGRI`, item.tgriDatei, data));

      if (item.sonstigeDatei) uploadActions.push(doUploadFile(`${fachkraftName}:Sonstige`, item.sonstigeDatei, data));
    });

    // Subject sollte folgendermaßen aussehen für ein Firmen/Antrag Dokument "data.firmenName:Upload_Dokument_Typ"
    if (data.betriebsHaftplichtDatei)
      uploadActions.push(
        doUploadFile(
          `${data.installationsUnternehmen.name}:${ApplicationFileType.Betriebshaftplicht}`,
          data.betriebsHaftplichtDatei,
          data
        )
      );

    if (data.gewerbeAnmeldungDatei)
      uploadActions.push(
        doUploadFile(
          `${data.installationsUnternehmen.name}:${ApplicationFileType.Gewerbeanmeldung}`,
          data.gewerbeAnmeldungDatei,
          data
        )
      );

    if (data.handwerksrollenEintragungDatei)
      uploadActions.push(
        doUploadFile(
          `${data.installationsUnternehmen.name}:${ApplicationFileType.Handwerksrolleneintragung}`,
          data.handwerksrollenEintragungDatei,
          data
        )
      );

    await Promise.all(uploadActions);

    return ApplicationId;
  };

  return useMutation<string, AxiosError, Antrag>(CreateApplication, {
    onSuccess: (_d, input) => {
      queryClient.invalidateQueries("Applications");

      // wenn erstantrag, dann firmendaten neu laden
      if (input.antragstyp === AntragsTyp.ERSTANMELDUNG) queryClient.invalidateQueries("Companies");

      log.debug("successfully created new application");
    },
    onError: (err) => logAxiosError("Error saving application", "Fehler beim Speichern des Antrags", err),
  });
};

export const useCreateGastApplication = (): UseMutationResult<string, AxiosError, GastAntrag> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);
  const queryClient = useQueryClient();
  const { mutateAsync: uploadDocument } = useGastUploadDokument();

  const CreateApplication = async (data: GastAntrag) => {
    log.trace({ obj: data }, "creating new GastAntrag");

    const konzessionsGebietId = data.konzessionsGebietId.startsWith("new-id-") ? "" : data.konzessionsGebietId;

    const postData = {
      ...data,
      konzessionsGebietId,
      ausweisGueltigkeit: format(new Date(data.ausweisGueltigkeit), "yyyy-MM-dd"),
    };

    const response = await api.post<GastAntrag, AxiosResponse<AntragResponse>>("/gastinstallteur", postData);
    log.trace({ obj: response }, "result from post application");
    const ApplicationId = response.data.applicationid;

    const doUploadFile = (subject: string, datei: File, seite: "Vorderseite" | "Rueckseite") => {
      return uploadDocument({
        applicationId: ApplicationId,
        datei: datei,
        subject: subject,
        seite: seite,
      });
    };

    // wenn bei den Fachkräften Dateien angefügt waren, dann werden die zum jeweiligen Unternehmen hochgeladen
    // Subject sollte folgendermaßen aussehen für ein Fachkraft Dokument. "data.fachkraftName:Upload_Dokument_Typ"
    const uploadActions: Promise<string>[] = [];

    // Subject sollte folgendermaßen aussehen für ein Firmen/Antrag Dokument "data.firmenName:Upload_Dokument_Typ"
    if (data.ausweisRueckseite)
      uploadActions.push(
        doUploadFile(`${data.name}:${ApplicationFileType.AusweisRueckseite}`, data.ausweisRueckseite, "Rueckseite")
      );

    if (data.ausweisVorderseite)
      uploadActions.push(
        doUploadFile(`${data.name}:${ApplicationFileType.AusweisVorderSeite}`, data.ausweisVorderseite, "Vorderseite")
      );

    await Promise.all(uploadActions);

    return ApplicationId;
  };

  return useMutation<string, AxiosError, GastAntrag>(CreateApplication, {
    onSuccess: () => {
      queryClient.invalidateQueries("Applications");
      log.debug("successfully created new application");
    },
    onError: (err) => logAxiosError("Error saving guest application", "Fehler beim Speichern des GastAntrags", err),
  });
};

export const useUpdateGastAusweisApplication = (): UseMutationResult<string, AxiosError, EditGastAntrag> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);
  const queryClient = useQueryClient();
  const { mutateAsync: uploadDocument } = useGastUploadDokument();

  const UpdateApplication = async (data: EditGastAntrag) => {
    log.trace({ obj: data }, "updating EditGastAntrag");

    const response = await api.post<EditGastAntrag, AxiosResponse<AntragResponse>>("/gastinstallteur", data);
    log.trace({ obj: response }, "result from put application");
    const ApplicationId = response.data.applicationid;

    const doUploadFile = (subject: string, datei: File, seite: "Vorderseite" | "Rueckseite") => {
      return uploadDocument({
        applicationId: ApplicationId,
        datei: datei,
        subject: subject,
        seite: seite,
      });
    };

    // wenn bei den Fachkräften Dateien angefügt waren, dann werden die zum jeweiligen Unternehmen hochgeladen
    // Subject sollte folgendermaßen aussehen für ein Fachkraft Dokument. "data.fachkraftName:Upload_Dokument_Typ"
    const uploadActions: Promise<string>[] = [];

    // Subject sollte folgendermaßen aussehen für ein Firmen/Antrag Dokument "data.firmenName:Upload_Dokument_Typ"
    if (data.ausweisRueckseite)
      uploadActions.push(
        doUploadFile(`${data.name}:${ApplicationFileType.AusweisRueckseite}`, data.ausweisRueckseite, "Rueckseite")
      );

    if (data.ausweisVorderseite)
      uploadActions.push(
        doUploadFile(`${data.name}:${ApplicationFileType.AusweisVorderSeite}`, data.ausweisVorderseite, "Vorderseite")
      );

    await Promise.all(uploadActions);

    return ApplicationId;
  };

  return useMutation<string, AxiosError, EditGastAntrag>(UpdateApplication, {
    onSuccess: () => {
      queryClient.invalidateQueries("Applications");
      log.debug("successfully updated new application");
    },
    onError: (err) => logAxiosError("Error saving guest application", "Fehler beim Speichern des GastAntrags", err),
  });
};

export const useUpdateGastMainDataApplication = (): UseMutationResult<string, AxiosError, EditGastAntrag> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);
  const queryClient = useQueryClient();

  const UpdateApplication = async (data: EditGastAntrag) => {
    log.trace({ obj: data }, "updating EditGastAntrag");
    const konzessionsGebietId = data.konzessionsGebietId.startsWith("new-id-") ? "" : data.konzessionsGebietId;
    const postData: EditGastAntrag = {
      ...data,
      konzessionsGebietId,
    };

    const response = await api.post<EditGastAntrag, AxiosResponse<AntragResponse>>("/gastinstallteur", postData);
    log.trace({ obj: response }, "result from put application");
    const ApplicationId = response.data.applicationid;

    return ApplicationId;
  };

  return useMutation<string, AxiosError, EditGastAntrag>(UpdateApplication, {
    onSuccess: () => {
      queryClient.invalidateQueries("Applications");
      log.debug("successfully updated new application");
    },
    onError: (err) => logAxiosError("Error saving guest application", "Fehler beim Speichern des GastAntrags", err),
  });
};

const base64RegExp = /data:\w*\/[\w.-]*;base64,/gm;

interface UploadDokument {
  unternehmenId: string;
  datei: File;
  subject: string;
}

type GastUploadDokument = Omit<UploadDokument, "unternehmenId"> & {
  seite: "Vorderseite" | "Rueckseite";
  applicationId: string;
};

const useUploadDokument = (): UseMutationResult<string, AxiosError, UploadDokument> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);
  const queryClient = useQueryClient();

  const toBase64 = (file: File) =>
    new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });

  const UploadDokument = async (data: UploadDokument) => {
    log.trace({ obj: data }, "uploading new datei");

    const base64String = (await toBase64(data.datei)).replace(base64RegExp, "");

    const response = await api.post(`/installationsunternehmen/${data.unternehmenId}/dokument`, {
      subject: data.subject,
      filename: data.datei.name,
      documentbody: base64String,
    });
    log.trace({ obj: response }, "result from post datei");
    const mainDataId = response.data.id;
    return mainDataId;
  };

  return useMutation<string, AxiosError, UploadDokument>(UploadDokument, {
    onSuccess: () => {
      queryClient.invalidateQueries("Licenses");
      log.debug("successfully uploaded new datei");
    },
    onError: (err) => logAxiosError("Error uploading file", "Fehler beim Speichern der Datei", err),
  });
};

const useGastUploadDokument = (): UseMutationResult<string, AxiosError, GastUploadDokument> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);
  const queryClient = useQueryClient();

  const toBase64 = (file: File) =>
    new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });

  const UploadDokument = async (data: GastUploadDokument) => {
    log.trace({ obj: data }, "uploading new datei");

    const base64String = (await toBase64(data.datei)).replace(base64RegExp, "");

    const response = await api.post(`/gastinstallateur/${data.applicationId}/ausweis/${data.seite}`, {
      subject: data.subject,
      filename: data.datei.name,
      documentbody: base64String,
    });
    log.trace({ obj: response }, "result from post datei");
    const mainDataId = response.data.id;
    return mainDataId;
  };

  return useMutation<string, AxiosError, GastUploadDokument>(UploadDokument, {
    onSuccess: () => {
      queryClient.invalidateQueries("Licenses");
      log.debug("successfully uploaded new datei");
    },
    onError: (err) => logAxiosError("Error uploading file", "Fehler beim Speichern der Datei", err),
  });
};
