import React from "react";
import { Column } from "react-table";
import DataTable, { DateCell } from "components/DataTable";
import { Antrag } from "interfaces/Application";
import { TableListProps } from "interfaces/TableListProps";
import { useApplicationsQueries } from "./applicationsQueries";

const columns: Column<Antrag>[] = [
  { Header: "Antragstyp", accessor: "antragstyp" },
  { Header: "Antragsdatum", accessor: "antragsDatum", Cell: DateCell },
  { Header: "Status", accessor: "status" },
];

const ApplicationList: React.VFC<TableListProps> = ({ pageSize = 25 }: TableListProps) => {
  const { data: applications, isLoading } = useApplicationsQueries();
  return (
    <>
      <h4>Anträge</h4>
      <DataTable data={applications} isLoading={isLoading} columns={columns} tablePageSize={pageSize} />
    </>
  );
};

export default ApplicationList;
