import React from "react";
import { Modal } from "react-bootstrap";

interface ModalHintProps {
  showDialog: boolean;
  onClose: () => void;
}

const ModalHint: React.FC<ModalHintProps> = ({ showDialog, onClose: handleClose }) => {
  return showDialog ? (
    <>
      <Modal size="xl" show={showDialog} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <h4>Hinweis</h4>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>
              Arbeiten an elektrischen sowie an gas- und wassertechnischen Anlagen dürfen nur durch in die
              Installateurverzeichnisse eines Netzbetreibers eingetragene Installationsunternehmen durchgeführt werden.
              Diese Regelung wird durch § 13 (2) NAV, § 13 (2) NDAV sowie durch § 12 (2) der AVBWasserV begründet. Die
              Vorschrift gilt für die gesamte Installation hinter der Hauptabsperreinrichtung bzw.
              Hausanschlusssicherung.
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default ModalHint;
