import React from "react";
import { Col, Container, Jumbotron, Row, Spinner } from "react-bootstrap";
import { backgroundStyles } from "components/BackgroundStyles";

const CheckLogin: React.VFC = () => (
  <div style={backgroundStyles} className="h-100">
    <Container>
      <Row style={{ marginTop: "10rem" }}>
        <Col md={8} lg={6}>
          <Jumbotron>
            <h1 className="display-4">
              Willkommen im <strong>Installateur</strong>Verzeichnis
            </h1>
            <div className="d-flex align-items-center justify-content-center h-100">
              <Spinner animation="border" role="status" variant="primary" />
              &nbsp;Prüfe Login-Daten ...
            </div>
          </Jumbotron>
        </Col>
      </Row>
    </Container>
  </div>
);

export default CheckLogin;
