import React, { useContext } from "react";
import { Button, Jumbotron, Row, Col, Container } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import { AuthContext } from "../services/authentication";
import { backgroundStyles } from "components/BackgroundStyles";

const Splash: React.FC = () => {
  // hooks
  const authContext = useContext(AuthContext);

  // states
  // parameter
  // queries und mutationen
  // effekte
  // daten
  // handler

  return (
    <div style={backgroundStyles} className="h-100">
      <Container>
        <Row style={{ marginTop: "10rem" }}>
          <Col md={8} lg={6}>
            <Jumbotron>
              <h1 className="display-4">
                Willkommen im <strong>Installateur</strong>Verzeichnis
              </h1>
              <p>
                Lassen Sie sich in unser <strong>Installateur</strong>Verzeichnis aufnehmen oder ändern Sie Ihre Daten.
                Eine Eintragung ist zulässig, wenn Ihr Unternehmen in einer Gemeinde ansässig ist, in der wir
                Netzbetreiber sind oder die technische Betriebsführung innehaben.
                <br />
                Diese Regelungen werden durch die § 13 NAV (Strom), § 13 NDAV (Gas) sowie § 12 AVBWasserV
                (Wasser)begründet.
              </p>
              <Button variant="secondary" onClick={authContext.signinRedirect}>
                {" "}
                <BsArrowRight className="text-primary" /> Anmelden
              </Button>
            </Jumbotron>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Splash;
