import React from "react";
import { Row, Col } from "react-bootstrap";

import { FormikControl } from "@gelsenwasser/react";

const BasicCompanyData: React.VFC = () => {
  return (
    <>
      <Row>
        <Col>
          <FormikControl name="name" label="Firma" disabled />
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={12}>
          <FormikControl name="telefon" label="Telefon" />
        </Col>
        <Col md={6} sm={12}>
          <FormikControl name="fax" label="Telefax" />
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={12}>
          <FormikControl name="email" label="E-Mail" disabled />
        </Col>
        <Col md={6} sm={12}>
          <FormikControl name="website" label="Internet" />
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={12}>
          <FormikControl name="strasse" label="Straße, Hausnr." />
        </Col>
        <Col md={2} sm={12}>
          <FormikControl name="plz" label="PLZ" />
        </Col>
        <Col md={4} sm={12}>
          <FormikControl label="Ort" name="ort" disabled />
        </Col>
      </Row>
    </>
  );
};

export default BasicCompanyData;
