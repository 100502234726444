import { AxiosError, AxiosResponse } from "axios";
import { useContext } from "react";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { log } from "common/Logger";
import { useErrorLogger } from "common/LoggerHooks";
import { InstallationsUnternehmen, InstallationsUnternehmenResponse } from "interfaces/Application";
import { ApiContext } from "services/ApiContext";

export const useUpdateMainData = (): UseMutationResult<string, AxiosError, InstallationsUnternehmen> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);
  const queryClient = useQueryClient();

  const UpdateMainData = async (data: InstallationsUnternehmen) => {
    log.trace({ obj: data }, "updating new maindata");

    const response = await api.patch<InstallationsUnternehmen, AxiosResponse<InstallationsUnternehmenResponse>>(
      `/installationsunternehmen/${data.id}`,
      {
        inhaber: data.inhaber,
        strasse: data.strasse,
        plz: data.plz,
        telefon: data.telefon,
        fax: data.fax,
        website: data.website,
      }
    );
    log.trace({ obj: response }, "result from put main data");
    const mainDataId = response.data.id;
    return mainDataId;
  };

  return useMutation<string, AxiosError, InstallationsUnternehmen>(UpdateMainData, {
    onSuccess: () => {
      queryClient.invalidateQueries("Licenses");
      log.debug("successfully created new main data");
    },
    onError: (err) => logAxiosError("Error saving main data", "Fehler beim Speichern der Stammdaten", err),
  });
};


