import React, { useContext, useEffect, useState } from "react";
import { AntragFormProps, GastAntrag, gastAntragSchema } from "../../interfaces/Application";
import { Formik, Form as FormikForm, FormikHelpers } from "formik";
import { ActionButton, AuthContext } from "@gelsenwasser/react";
import { Row, Col, Button } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import { log } from "../../common/Logger";
import { useCreateGastApplication } from "../applications/applicationMutations";
import { useHistory } from "react-router-dom";

const GastAntragForm: React.FC<AntragFormProps> = ({
  formMode,
  viewMode,
  setViewMode,
  setShowLoader,
  handlePreviousStep,
  children,
}) => {
  // hooks
  const history = useHistory();
  const auth = useContext(AuthContext);
  // states
  const [showBackButton, setShowBackButton] = useState(false);
  // parameter
  // queries und mutationen
  const { mutate: createGastApplication } = useCreateGastApplication();
  // effekte
  useEffect(() => {
    setShowBackButton(formMode !== "register" || viewMode === "guestdatadocuments" || viewMode === "guestsummary");
  }, [setShowBackButton, formMode, viewMode]);
  // daten
  const antrag = gastAntragSchema.getDefault();
  // handler
  const handleNextStep = (values: GastAntrag, actions: FormikHelpers<GastAntrag>) => {
    if (viewMode === "guestdata") {
      log.debug("moving to next step 'employees'");
      log.trace({ obj: values }, "current form values");
      setViewMode("guestdatadocuments");
      actions.setSubmitting(false);
    } else if (viewMode === "guestdatadocuments") {
      let formStepErrors = false;
      actions.setSubmitting(false);
      if (!values.ausweisGueltigkeit) {
        actions.setFieldError("ausweisGueltigkeit", "Bitte geben Sie die Gültigkeit an!");
        formStepErrors = true;
      }
      if (!values.ausweisNummer) {
        actions.setFieldError("ausweisNummer", "Bitte geben Sie die Ausweisnummer an!");
        formStepErrors = true;
      }
      if (!values.ausweisVorderseite) {
        actions.setFieldError("ausweisVorderseite", "Bitte eine Datei hochladen!");
        formStepErrors = true;
      }
      if (!values.ausweisRueckseite) {
        actions.setFieldError("ausweisRueckseite", "Bitte eine Datei hochladen!");
        formStepErrors = true;
      }

      if (!formStepErrors) {
        log.debug("No errors found - Setting to summary");
        setViewMode("guestsummary");
      }
    } else {
      setShowLoader(true);
      createGastApplication(values, {
        onSuccess: (data) => {
          log.debug({ obj: data }, `Gastantrag erstellt`);
          history.push("/");
        },
        onError: (err) => {
          log.error({ obj: err }, `Fehler bei Gastantrag Erstellung`);
        },
        onSettled: () => {
          setShowLoader(false);
          actions.setSubmitting(false);
        },
      });
    }
  };
  return (
    <Formik initialValues={antrag} onSubmit={handleNextStep} validationSchema={gastAntragSchema}>
      {({ handleSubmit, setFieldValue }): JSX.Element => {
        useEffect(() => {
          // bei der registrierung die eMail-Adresse aus dem Profil in den Antrag übernehmen
          if (formMode === "register")
            auth
              .getUser()
              .then((userData) => {
                log.debug({ obj: userData?.profile }, "got user profile");
                if (userData) setFieldValue("email", userData.profile["emails"][0]);
              })
              .catch((err) => log.error(err, "Error retrieving user"));
        }, [auth]);
        return (
          <FormikForm onSubmit={handleSubmit}>
            {children}
            <Row>
              <Col>
                {/* Bei der Registrierung gibt es für die MainData keinen Zurück-Button */}
                {showBackButton && <ActionButton caption="Zurück" icon={BsArrowLeft} onClick={handlePreviousStep} />}
              </Col>
              {viewMode !== "selectform" && (
                <Col className="d-flex justify-content-end">
                  {viewMode === "guestdata" || viewMode === "guestdatadocuments" ? (
                    <ActionButton as="button" type="submit" caption="Weiter" iconSide="right" />
                  ) : (
                    <Button type="submit">Speichern</Button>
                  )}
                </Col>
              )}
            </Row>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default GastAntragForm;
