import React from "react";

import { emptyFachkraft, Fachkraft } from "interfaces/Application";
import { formMethod } from "interfaces/TableListProps";
import { Formik, Form as FormikForm, Field, useFormikContext, FormikHelpers } from "formik";
import { Form, Alert, Button, Col, Modal, Row } from "react-bootstrap";
import AlertTriangle from "components/AlertTriangle";
import * as yup from "yup";
import { FormikCheck, FormikControl, FormikDateField } from "@gelsenwasser/react";
import { useLicensesQueries } from "../licenses/licensesQueries";
import { useSkillsQueries } from "../applications/applicationsQueries";
import { determineOpeningPage } from "../../interfaces/Pages";
import { FileError } from "../../components/FileError";

interface Props {
  fachkraft: Fachkraft;
  alteFachkraft?: Fachkraft;
  //todo: maybe decide based on fachkraft.status, if this is set from outside!
  formMethod?: formMethod;
  enableSparteSelection?: boolean;
  onClose: (isCancel: boolean, fachkraft?: Fachkraft) => void;
}

const formModeSelector = (formMethod: formMethod, oldEmployee: Fachkraft, enableSparteSelection: boolean) => {
  switch (formMethod) {
    case "create":
    case "edit":
      return <CreateUpdateForm formMethod={formMethod} enableSparteSelection={enableSparteSelection} />;

    case "delete":
      return <DeleteForm />;

    case "replace":
      return <ReplaceForm fachkraft={oldEmployee} />;
  }
};

const EmployeeBasicForm: React.VFC<Props> = ({
  fachkraft,
  alteFachkraft = emptyFachkraft,
  onClose,
  formMethod = "create",
  enableSparteSelection = false,
}: Props) => {
  // hooks
  // states
  // parameter
  // queries und mutationen
  // effekte
  // daten
  const formBody = formModeSelector(formMethod, alteFachkraft, enableSparteSelection);
  const saveButtonCaption = formMethod !== "delete" ? "Speichern" : "Löschen";

  const formSchema = yup.object().shape({
    vorname: yup.string().max(100, "Nicht mehr als 100 Zeichen").required("Vorname ist notwendig!"),
    nachname: yup.string().max(100, "Nicht mehr als 100 Zeichen").required("Nachname ist notwendig!"),
  });

  const handleSubmit = (values: Fachkraft, actions: FormikHelpers<Fachkraft>) => {
    let hasErrors = false;
    if (!values.befaehigungDatei && !values.id) {
      hasErrors = true;
      actions.setFieldError("befaehigungDatei", "Bitte laden Sie eine Datei hoch!");
    }
    if (!hasErrors) {
      onClose(false, values);
    }
  };

  return (
    <>
      <Formik initialValues={fachkraft} onSubmit={handleSubmit} validationSchema={formSchema}>
        {({ handleSubmit }) => {
          return (
            <FormikForm onSubmit={handleSubmit}>
              <Modal.Body>
                {formBody}
                <Row>
                  <Col>
                    <Alert variant="info">
                      <AlertTriangle color="#37AFB9" />
                      <p>
                        Voraussetzungen zur Eintragung für Fachkräfte. Dokumente als einzelne Dokumente hochladen und
                        nicht als Zip Datei:
                      </p>
                      <p>
                        <ol>
                          <li>Meisterprüfung</li>
                          <li>Sicherheits - und Instandhaltungstechnik (Prüfung + Lehrgang)</li>
                          <li>aktueller TGRI-Lehrgang</li>
                          <li>Sonstige Dokumente</li>
                        </ol>
                      </p>
                      <p>Pro Punkt kann eine Datei hochgeladen werden</p>
                      <p>
                        Die Verarbeitung personenbezogener Daten erfolgt nur zum Zweck der Bearbeitung Ihres Anliegens.
                        Weitere Informationen zum Thema Datenschutz finden Sie auf unserer{" "}
                        <a rel="noreferrer" href={determineOpeningPage(window.location.hostname)} target="_blank">
                          Internetseite
                        </a>
                        .
                      </p>
                      <p>
                        Bei Fragen zur Bearbeitung der Daten wenden Sie sich bitte an den zuständigen Ansprechpartner
                        auf der Startseite.
                      </p>
                    </Alert>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Row>
                  <Col>
                    <Button className="mr-3" as="button" type="submit">
                      {saveButtonCaption}
                    </Button>
                    <Button onClick={() => onClose(true)} variant="secondary">
                      Abbrechen
                    </Button>
                  </Col>
                </Row>
              </Modal.Footer>
            </FormikForm>
          );
        }}
      </Formik>
    </>
  );
};

export default EmployeeBasicForm;

const ReplaceForm: React.VFC<Pick<Props, "fachkraft">> = ({ fachkraft }: Pick<Props, "fachkraft">) => {
  return (
    <>
      <Row>
        <Col>
          <span>Alte Fachkraft: {`${fachkraft?.nachname}, ${fachkraft?.vorname}`}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormikControl name="vorname" label="Vorname Fachkraft" />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormikControl name="nachname" label="Nachname Fachkraft" />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormikControl
            name="entferntAm"
            label="Datum des Wechsels"
            placeholder="Datum"
            type="date"
            as={Field}
            component={FormikDateField}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormikControl name="email" label="E-Mail" />
        </Col>
      </Row>
      <CreateFormPart />

      <FileUploads />
    </>
  );
};

const FileUploads: React.VFC = () => {
  const { setFieldValue } = useFormikContext<Fachkraft>();

  const handleMeisterbriefFileSelection: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.currentTarget.files && e.currentTarget.files.length > 0)
      setFieldValue("befaehigungDatei", e.currentTarget.files[0]);
  };

  const handleSicherheitsFileSelection: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.currentTarget.files && e.currentTarget.files.length > 0)
      setFieldValue("sicherheitsDatei", e.currentTarget.files[0]);
  };

  const handleTrgiFileSelection: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.currentTarget.files && e.currentTarget.files.length > 0) setFieldValue("tgriDatei", e.currentTarget.files[0]);
  };

  const handleSonstigeileSelection: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.currentTarget.files && e.currentTarget.files.length > 0)
      setFieldValue("sonstigeDatei", e.currentTarget.files[0]);
  };

  return (
    <>
      <Row>
        <Col>
          <Form.File label="Meisterbrief" onChange={handleMeisterbriefFileSelection} data-browse="Datei auswählen" />
          <FileError errorFieldName="befaehigungDatei" />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.File
            label="Sicherheits - und Instandhaltungstechnik (Prüfung + Lehrgang)"
            onChange={handleSicherheitsFileSelection}
            data-browse="Datei auswählen"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.File label="aktueller TRGI-Lehrgang" onChange={handleTrgiFileSelection} data-browse="Datei auswählen" />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.File label="Sonstige Dokumente" onChange={handleSonstigeileSelection} data-browse="Datei auswählen" />
        </Col>
      </Row>
    </>
  );
};

const CreateUpdateForm: React.FC<Pick<Props, "formMethod" | "enableSparteSelection">> = ({
  formMethod,
  enableSparteSelection,
}) => {
  const meineKonzessionen = useLicensesQueries();

  return (
    <>
      {formMethod === "create" && enableSparteSelection && (
        <Row>
          <Col>
            <FormikControl label="Sparte" name="sparte" as="select">
              {meineKonzessionen.data?.map((konzession) => (
                <option key={`sparte_${konzession.sparte}`} value={konzession.sparte}>
                  {konzession.sparte}
                </option>
              ))}
            </FormikControl>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <FormikControl name="vorname" label="Vorname Fachkraft" />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormikControl name="nachname" label="Nachname Fachkraft" />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormikControl name="email" label="E-Mail" />
        </Col>
      </Row>
      {formMethod === "create" || formMethod === "replace" ? <CreateFormPart /> : <UpdateFormPart />}
      {formMethod !== "delete" && <FileUploads />}
    </>
  );
};

const CreateFormPart: React.VFC = () => {
  const { values } = useFormikContext<Fachkraft>();
  return (
    <>
      <Row>
        <Col>Die verantwortliche Fachkraft besitzt</Col>
      </Row>
      <Row>
        <Col>
          <FormikCheck
            name="hatMeisterbrief"
            value={values.hatMeisterbrief.toString()}
            label={
              <div>
                die Befähigungen nach den{" "}
                <a
                  href="https://www.gw-energienetze.de/fileadmin/pdf/service/installateurverzeichnis/richtlinie_gwn_installateurverzeichnis_2019.pdf"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Richtlinien
                </a>{" "}
                Paragraph 5
              </div>
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormikCheck
            name="hatAndereQualifikation"
            value={values.hatAndereQualifikation.toString()}
            label="andere Qualifikation"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {values.hatAndereQualifikation && (
            <FormikControl as="textarea" name="beschreibungQualifikation" label="Beschreibung" />
          )}
        </Col>
      </Row>
    </>
  );
};

const UpdateFormPart: React.VFC = () => {
  const { data: skills } = useSkillsQueries();

  return (
    <>
      <Row>
        <Col>Fortbildungen zum Erhalt der Befähigung</Col>
      </Row>
      <Row>
        <Col>
          <FormikControl as="select" name="befaehigungId">
            {skills?.map((s) => (
              <option key={`skill_${s.befaehigungsnummer}`} value={s.id}>
                {s.befaehigungstext}
              </option>
            ))}
          </FormikControl>
        </Col>
        <Col>
          <FormikControl
            name="erlangungbefaehigung"
            placeholder="Datum"
            type="date"
            as={Field}
            component={FormikDateField}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormikControl
            name="entferntAm"
            label="Datum Entfernung"
            placeholder="Datum"
            type="date"
            as={Field}
            component={FormikDateField}
          />
        </Col>
        <Col />
      </Row>
    </>
  );
};

const DeleteForm: React.VFC = () => {
  return (
    <>
      <Row>
        <Col>
          <h6>Möchten Sie die folgende Fachkraft wirklich löschen?</h6>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormikControl name="vorname" label="Vorname Fachkraft" disabled />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormikControl name="nachname" label="Nachname Fachkraft" disabled />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormikControl name="email" label="E-Mail" disabled />
        </Col>
      </Row>
    </>
  );
};

