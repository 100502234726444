import { FormikControl, FormikDateField, FormikTypeahead, FormSection } from "@gelsenwasser/react";
import { log } from "common/Logger";
import AlertTriangle from "components/AlertTriangle";
import { useCompaniesQueries, useGemeindenQueries } from "domain/applications/applicationsQueries";
import { Field, useFormikContext } from "formik";
import { Antrag, LicenseFormMode } from "interfaces/Application";
import { emptyWerk, Gemeinde, Sparte, Werk } from "interfaces/Municipal";
import React, { useEffect, useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { determineOpeningPage } from "../../interfaces/Pages";
import { useGetAvailableLicenses } from "./licensesQueries";

interface MainDataProps {
  formMode: LicenseFormMode;
}

const MainData: React.VFC<MainDataProps> = ({ formMode }: MainDataProps) => {
  // hooks
  const { values, setFieldValue } = useFormikContext<Antrag>();

  // states
  const [sparten, setSparten] = useState<Sparte[]>([]);
  const [werk, setWerk] = useState<Werk>(emptyWerk);

  // parameter
  // queries und mutationen
  const { data: gemeinden } = useGemeindenQueries();
  const { data: companies } = useCompaniesQueries();
  const availableLicenses = useGetAvailableLicenses();

  // effekte
  useEffect(() => {
    if (companies && gemeinden && companies.length > 0) {
      setFieldValue("installationsUnternehmenId", companies[0].id);
      setFieldValue("installationsUnternehmen", companies[0]);

      // suchen der gemeinde meines unternehmens ... um dies vorauszuwählen
      const foundGemeinde = gemeinden?.find((item) => item.id == companies[0].gemeindeId);
      if (foundGemeinde) {
        setSparten(foundGemeinde.sparte);
        setFieldValue("sparte", foundGemeinde.sparte[0].id.toString());
        setWerkBySpartenValue(foundGemeinde.sparte[0], foundGemeinde.gemeindename);
        setFieldValue("installationsUnternehmen.ort", foundGemeinde.gemeindename);
        setFieldValue("gewerbeAngemeldetIn", foundGemeinde.gemeindename);
      }
    }
  }, [gemeinden, companies]);

  useEffect(() => {
    setSparten(
      availableLicenses.map((item) => {
        return { id: Number(item.sparteId), name: item.sparteName };
      })
    );
  }, [availableLicenses]);

  // daten
  const isExtend = formMode === "extend";

  // handler
  const handleSparteChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    const value = e.target.value;
    setFieldValue("sparte", value);
    setWerkBySpartenValue({ name: value, id: Number(e.target.id) }, values.installationsUnternehmen.ort);
  };
  const handleGemeineChange = (possibleGemeinden: Gemeinde[]) => {
    if (possibleGemeinden.length > 0) {
      const firstGemeinde = possibleGemeinden[0];
      setFieldValue("installationsUnternehmen.ort", firstGemeinde.gemeindename);
      setFieldValue("gewerbeAngemeldetIn", firstGemeinde.gemeindename);
      setFieldValue("installationsUnternehmen.gemeindeId", firstGemeinde.id);
      if (firstGemeinde.sparte && firstGemeinde.sparte[0]) {
        setSparten(firstGemeinde.sparte);
        setFieldValue("sparte", firstGemeinde.sparte[0].id.toString());
        setWerkBySpartenValue(firstGemeinde.sparte[0], firstGemeinde.gemeindename);
      }
    } else {
      log.debug("no gemeinde selected, clearing dependend data");
      setSparten([]);
      setFieldValue("sparte", "");
      setFieldValue("installationsUnternehmen.ort", "");
      setFieldValue("gewerbeAngemeldetIn", "");
      setFieldValue("installationsUnternehmen.gemeindeId", "");
      setWerk(emptyWerk);
    }
  };

  const setWerkBySpartenValue = (sparte: Sparte, selectedOrt: string) => {
    const gemeinde = gemeinden?.filter((g) => g.gemeindename === selectedOrt);
    if (gemeinde && gemeinde[0]) {
      const possibleWerke = gemeinde[0].werke.filter((w) => w.sparte === sparte.name);
      if (possibleWerke && possibleWerke[0]) setWerk(possibleWerke[0]);
    }
  };

  const konzessionLabel = (
    <>
      Konzession <span style={{ fontSize: "0.75em" }}>(es können später weitere Konzessionen hinzugefügt werden)</span>
    </>
  );

  return (
    <>
      <Row>
        <Col>
          <Alert variant="info">
            <AlertTriangle color="#37AFB9" />
            <p>
              Bitte beachten Sie die{" "}
              <a
                rel="noreferrer noopener"
                href="https://www.gw-energienetze.de/fileadmin/pdf/service/installateurverzeichnis/richtlinie_gwn_installateurverzeichnis_2019.pdf"
                target="_blank"
              >
                Richtlinien
              </a>{" "}
              für den Abschluss von Verträgen mit Installationsunternehmen zur Herstellung, Veränderung, Instandsetzung
              und Wartung von Gas- und Wasserinstallationen auf unserer{" "}
              <a rel="noreferrer" href={determineOpeningPage(window.location.hostname)} target="_blank">
                Internetseite
              </a>
              .
            </p>
            <p>
              Die Verarbeitung personenbezogener Daten erfolgt nur zum Zweck der Bearbeitung Ihres Anliegens. Weitere
              Informationen zum Thema Datenschutz finden Sie auf unserer{" "}
              <a rel="noreferrer" href={determineOpeningPage(window.location.hostname)} target="_blank">
                Internetseite
              </a>
              .
            </p>
            <p>
              Bei Fragen zur Registrierung wenden Sie sich bitte an den zuständigen Ansprechpartner auf unserer{" "}
              <a rel="noreferrer" href={determineOpeningPage(window.location.hostname)} target="_blank">
                Internetseite
              </a>
              .
            </p>
          </Alert>
        </Col>
      </Row>
      <FormSection title="Konzession">
        <Row>
          <Col md={6} sm={12}>
            <FormikTypeahead
              name="installationsUnternehmen.gemeindeId"
              label="Firmensitz"
              options={
                gemeinden
                  ?.sort((a, b) => {
                    if (a.gemeindename > b.gemeindename) return 1;
                    if (a.gemeindename < b.gemeindename) return -1;
                    return 0;
                  })
                  .filter((g) => g.gemeindename) ?? []
              }
              idField="id"
              showClear
              disabled={isExtend}
              labelKey="gemeindename"
              onChange={handleGemeineChange}
            />
          </Col>
          <Col md={6} sm={12}>
            <FormikControl label={konzessionLabel} name="sparte" as="select" onChange={handleSparteChange}>
              {sparten.map((s) => (
                <option key={`sparte_${s.id}`} value={s.id}>
                  {s.name}
                </option>
              ))}
            </FormikControl>
          </Col>
        </Row>
        <Row>
          <Col>{werk.name}</Col>
        </Row>
      </FormSection>
      <FormSection title="Stammdaten">
        {isExtend && (
          <Alert variant="info">
            Die Adressdaten können nicht im Rahmen einer Erweiterung der Konzession geändert werden. Um die Adressdaten
            zu ändern verwenden Sie bitte die Änderung Stammdaten von der Startseite.
          </Alert>
        )}
        <Row>
          <Col>
            <FormikControl name="installationsUnternehmen.name" label="Firma" disabled={isExtend} />
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <FormikControl name="installationsUnternehmen.telefon" label="Telefon" disabled={isExtend} />
          </Col>
          <Col md={6} sm={12}>
            <FormikControl name="installationsUnternehmen.fax" label="Telefax" disabled={isExtend} />
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <FormikControl name="installationsUnternehmen.email" label="E-Mail" disabled />
          </Col>
          <Col md={6} sm={12}>
            <FormikControl name="installationsUnternehmen.website" label="Internet" disabled={isExtend} />
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <FormikControl name="installationsUnternehmen.strasse" label="Straße, Hausnr." disabled={isExtend} />
          </Col>
          <Col md={2} sm={12}>
            <FormikControl name="installationsUnternehmen.plz" label="PLZ" disabled={isExtend} />
          </Col>
          <Col md={4} sm={12}>
            <FormikControl label="Ort" name="installationsUnternehmen.ort" disabled />
          </Col>
        </Row>

        <Row>
          <Col>
            <FormikControl name="inhaber" label="Inhaber" />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormikControl name="handwerksrolleHauskammer" label="Eingetragen in Handwerksrolle der Handwerkskammer" />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormikControl name="handwerk" label="Handwerk" />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormikControl name="teiltaetigkeit" label="Teiltätigkeit" />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormikControl
              name="anschriftWerkstatt"
              label="Anschrift der Werkstatt wenn abweichend von Firmenadresse"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormikControl name="mitgliedInnung" label="Mitglied der Innung" />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormikControl
              name="gewerbeAngemeldetAm"
              label="Gewerbe angemeldet am"
              placeholder="Datum"
              type="date"
              as={Field}
              component={FormikDateField}
            />
          </Col>
          <Col>
            <FormikControl name="gewerbeAngemeldetIn" label="Gewerbe angemeldet in" disabled />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormikControl name="haftplichtversicherung" label="Haftpflichtversicherung" />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormikControl name="anzahlBeschaeftigte.angestellte" label="Anzahl der Beschäftigten: Angestellte" />
          </Col>
          <Col>
            <FormikControl name="anzahlBeschaeftigte.gesellen" label="Anzahl der Beschäftigten: Gesellen" />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormikControl name="anzahlBeschaeftigte.helfer" label="Anzahl der Beschäftigten: Helfer" />
          </Col>
          <Col>
            <FormikControl name="anzahlBeschaeftigte.auszubildende" label="Anzahl der Beschäftigten: Auszubildende" />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormikControl
              name="andererInstallateurvertragMit"
              label="Ein anderer Installateurvertrag wurde bereits geschlossen mit"
            />
          </Col>
        </Row>
      </FormSection>
    </>
  );
};

export default MainData;

