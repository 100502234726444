import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { BsHouseDoor } from "react-icons/bs";
import { Link } from "react-router-dom";

interface SimpleBreadcrumbProps {
  subPageTitle?: string;
}
const SimpleBreadcrumb: React.VFC<SimpleBreadcrumbProps> = ({ subPageTitle }: SimpleBreadcrumbProps) => {
  const isHome = !subPageTitle;
  const homeItemClass = "d-flex align-items-center";
  return (
    <Breadcrumb className="mt-3 d-print-none">
      <Breadcrumb.Item
        active={isHome}
        linkAs={Link}
        linkProps={{ to: "/", className: homeItemClass }}
        className={homeItemClass}
      >
        <BsHouseDoor className="mr-2" />
        Home
      </Breadcrumb.Item>
      {!isHome && <Breadcrumb.Item active>{subPageTitle}</Breadcrumb.Item>}
    </Breadcrumb>
  );
};

export default SimpleBreadcrumb;
