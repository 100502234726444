import React from "react";
import { Column } from "react-table";
import DataTable from "components/DataTable";
import { TableListProps } from "interfaces/TableListProps";
import { useEmployeesQueries } from "./employeesQueries";

import { Fachkraft } from "interfaces/Application";

const columns: Column<Fachkraft>[] = [
  { Header: "Nachname", accessor: "nachname" },
  { Header: "Vorname", accessor: "vorname" },
  { Header: "Sparte", accessor: "sparte" },
];

const headerEnd: React.CSSProperties = {
  textAlign: "end",
};

export const EmployeeList: React.FC<TableListProps> = ({ pageSize = 5 }) => {
  const { data: employees, isLoading } = useEmployeesQueries();

  return (
    <>
      <h4>Meine Fachkräfte</h4>
      <DataTable
        getHeaderProps={(hp) => (hp.Header === "Aktion" ? { style: headerEnd } : {})}
        data={employees}
        isLoading={isLoading}
        columns={columns}
        tablePageSize={pageSize}
      />
    </>
  );
};

export default EmployeeList;
