import React from "react";
import { Route, Switch } from "react-router-dom";

import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";

import "@gelsenwasser/react/lib/Styles/gwag-theme.scss";
import "react-bootstrap-typeahead/css/Typeahead.css";

import { NotificationProvider } from "@gelsenwasser/react";
import { IDENTITY_CONFIG } from "./services/authentication/AuthConst";
import { AuthProvider, LogInCallback, LogInSilentCallback, LogOutCallback } from "./services/authentication";

// static components
import { Layout } from "./components/Layout";
import { Home } from "./domain/Home";
import NoMatch from "./domain/NoMatch";
import { ApiContextProvider } from "./services/ApiContext";
import ProtectedRoute from "./components/ProtectedRoute";
import License from "./domain/licenses/CreateOrExtendLicense";
import AlterEmployee from "./domain/employees/AlterEmployee";
import EditMainData from "./domain/maindata/Edit";
import EditGastAusweis from "./domain/licenses/EditGastAusweis";
import EditGuestMainData from "./domain/licenses/EditGuestMainData";
import {RegisterGuest,LogInRegisterCallback} from "domain/RegisterGuest";

const queryClient = new QueryClient();
export default class App extends React.Component {
  static displayName = App.name;

  render(): JSX.Element {
    return (
      <>
        <AuthProvider config={IDENTITY_CONFIG}>
          <ApiContextProvider>
            <NotificationProvider>
              <QueryClientProvider client={queryClient}>
                <Layout>
                  <Switch>
                    {/* Home */}
                    <Route exact path="/" component={Home} />
                    <Route path="/registerGuest" component={RegisterGuest} />

                    {/* Authentication */}
                    <Route path="/signin-oidc" component={LogInCallback} />
                    <Route path="/signinsilent-oidc" component={LogInSilentCallback} />
                    <Route path="/signinregister-oidc" component={LogInRegisterCallback} />
                    <Route path="/signout-oidc" component={LogOutCallback} />

                    {/* Kundenbereich */}
                    <ProtectedRoute path="/employees" component={AlterEmployee} />

                    <ProtectedRoute path="/license" component={() => <License formMode="extend" />} />
                    <ProtectedRoute path="/register" component={() => <License formMode="register" />} />
                    <ProtectedRoute path="/editmaindata" component={EditMainData} />
                    <ProtectedRoute path="/editguestdata" component={EditGuestMainData} />
                    <ProtectedRoute path="/editpass" component={EditGastAusweis} />

                    {/* Unbekannte Seite */}
                    <Route path="*" component={NoMatch} />
                  </Switch>
                </Layout>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </NotificationProvider>
          </ApiContextProvider>
        </AuthProvider>
      </>
    );
  }
}
