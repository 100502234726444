import React, { useContext, useEffect } from "react";
import { Antrag, AntragFormProps, AntragsTyp, CreateNewAntrag, antragSchema } from "../../interfaces/Application";
import { ActionButton, AuthContext } from "@gelsenwasser/react";
import { FormikHelpers, Formik, Form as FormikForm } from "formik";
import { Row, Col, Button } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import { log } from "../../common/Logger";
import { useCreateApplication } from "../applications/applicationMutations";
import { useHistory } from "react-router-dom";

const AntragForm: React.FC<AntragFormProps> = ({
  children,
  formMode,
  handlePreviousStep,
  setShowLoader,
  setViewMode,
  viewMode,
  pageTitle,
}) => {
  const history = useHistory();
  const auth = useContext(AuthContext);

  const { mutate: createApplication } = useCreateApplication();

  const antrag = CreateNewAntrag();
  log.trace({ obj: antrag }, "preparing new antrag");

  const handleNextStep = (values: Antrag, actions: FormikHelpers<Antrag>) => {
    if (viewMode === "maindata") {
      log.debug("moving to next step 'employees'");
      log.trace({ obj: values }, "current form values");
      setViewMode("documents");
      actions.setSubmitting(false);
    } else if (viewMode === "documents") {
      let documentErrors = false;
      actions.setSubmitting(false);
      if (!values.betriebsHaftplichtDatei) {
        actions.setFieldError("betriebsHaftplichtDatei", "Bitte eine Datei hochladen!");
        documentErrors = true;
      }
      if (!values.gewerbeAnmeldungDatei) {
        actions.setFieldError("gewerbeAnmeldungDatei", "Bitte eine Datei hochladen!");
        documentErrors = true;
      }
      if (!values.handwerksrollenEintragungDatei) {
        actions.setFieldError("handwerksrollenEintragungDatei", "Bitte eine Datei hochladen!");
        documentErrors = true;
      }

      if (!documentErrors) setViewMode("employees");
    } else {
      setShowLoader(true);
      if (formMode === "register") {
        values.antragstyp = AntragsTyp.ERSTANMELDUNG;
        // bei der Registrierung sicherstellen, dass die Sparte der Fachkräfte gleich der Sparte des Antrags ist!
        values.fachkraefte.forEach((item) => {
          item.sparte = values.sparte;
        });
      } else values.antragstyp = AntragsTyp.ERWEITERUNG;

      log.debug({ obj: values }, "antrag for konzession (register or extend)");
      createApplication(values, {
        onSuccess: (data) => {
          log.debug({ obj: data }, `antrag erstellt für ${pageTitle}`);
          history.push("/");
        },
        onError: (err) => {
          log.error({ obj: err }, `Fehler bei ${pageTitle}`);
        },
        onSettled: () => {
          setShowLoader(false);
          actions.setSubmitting(false);
        },
      });
    }
  };
  return (
    <Formik initialValues={antrag} onSubmit={handleNextStep} validationSchema={antragSchema}>
      {({ handleSubmit, setFieldValue }): JSX.Element => {
        useEffect(() => {
          // bei der registrierung die eMail-Adresse aus dem Profil in den Antrag übernehmen
          if (formMode === "register")
            auth.getUser().then((userData) => {
              log.debug({ obj: userData?.profile }, "got user profile");
              if (userData) setFieldValue("installationsUnternehmen.email", userData.profile["emails"][0]);
            });
        }, [auth]);

        return (
          <FormikForm onSubmit={handleSubmit}>
            {children}
            <Row>
              <Col>
                {/* Bei der Registrierung gibt es für die MainData keinen Zurück-Button */}
                {(formMode !== "register" || viewMode === "documents" || viewMode === "employees") && (
                  <ActionButton caption="Zurück" icon={BsArrowLeft} onClick={handlePreviousStep} />
                )}
              </Col>
              {viewMode !== "selectform" && (
                <Col className="d-flex justify-content-end">
                  {viewMode === "maindata" || viewMode === "documents" ? (
                    <ActionButton as="button" type="submit" caption="Weiter" iconSide="right" />
                  ) : (
                    <Button type="submit">Speichern</Button>
                  )}
                </Col>
              )}
            </Row>
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default AntragForm;
