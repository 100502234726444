import React from "react";
import { GastAntrag } from "../../interfaces/Application";
import { Row, Col } from "react-bootstrap";
import { useFormikContext } from "formik";
import { FormSection } from "@gelsenwasser/react";
import { format } from "date-fns";

interface SummaryLabelProps {
  label: string;
  value: string | JSX.Element;
}
const SummaryLabel: React.FC<SummaryLabelProps> = ({ label, value }) => {
  return (
    <>
      <Col md={2}>
        <strong>{label}</strong>
      </Col>
      <Col md={4}>{value}</Col>
    </>
  );
};

const GastAntragSummary: React.VFC = () => {
  // hooks
  const { values } = useFormikContext<GastAntrag>();

  // states
  // parameter
  // queries und mutationen
  // effekte
  // daten
  // handler

  return (
    <>
      <FormSection title="Stammdaten">
        <Row>
          <SummaryLabel label="Sparte" value={values.sparteName} />
        </Row>
        <Row>
          <SummaryLabel label="Inhaber" value={values.inhaber} />
        </Row>
        <Row>
          <SummaryLabel label="Firmenname" value={values.name} />
        </Row>
        <Row>
          <SummaryLabel label="Name" value={values.vorname + " " + values.nachname} />
        </Row>
        <Row>
          <SummaryLabel label="Telefon" value={values.telefon} />
          <SummaryLabel label="Fax" value={values.fax} />
        </Row>
        <Row>
          <SummaryLabel label="Mobiltelefon" value={values.mobiltelefon} />
          <SummaryLabel label="E-Mail" value={values.email} />
        </Row>
        <Row>
          <SummaryLabel label="Internet" value={values.website} />
        </Row>
        <Row>
          <SummaryLabel
            label="Adresse"
            value={
              <>
                {values.strasse}
                <br />
                {values.plz + " " + values.ort}
              </>
            }
          />
        </Row>
        <Row>
          <SummaryLabel
            label="Ein anderer Installateurvertrag wurde bereits geschlossen mit"
            value={values.andererInstallateurvertragMit}
          />
        </Row>
        <Row>
          <SummaryLabel label="Konzessionsgebiet" value={values.konzessionsGebietName} />
        </Row>
      </FormSection>
      <FormSection title="Ausweis">
        <Row>
          <SummaryLabel label="Ausweisnummer" value={values.ausweisNummer} />
        </Row>
        <Row>
          <SummaryLabel label="Gültig bis" value={format(new Date(values.ausweisGueltigkeit), "dd.MM.yyyy")} />
        </Row>
        <Row>
          <SummaryLabel
            label="Ausweis Vorderseite"
            value={typeof values.ausweisVorderseite === "object" ? values.ausweisVorderseite.name : ""}
          />
          <SummaryLabel
            label="Ausweis Rückseite"
            value={typeof values.ausweisRueckseite === "object" ? values.ausweisRueckseite.name : ""}
          />
        </Row>
      </FormSection>
    </>
  );
};

export default GastAntragSummary;
