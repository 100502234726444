export interface Gemeinde {
  id: string;
  gemeindename: string;
  sparte: Sparte[];
  werke: Werk[];
}

export interface Konzessionsgebiet {
  id: string;
  name: string;
}

export interface Werk {
  name: string;
  sparte: string;
}

export const emptyWerk: Werk = {
  name: "",
  sparte: "",
};

export interface Sparte {
  id: number;
  name: string;
}
