import { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { log } from "common/Logger";
import { useErrorLogger } from "common/LoggerHooks";
import { Konzession, MoeglicheKonzession } from "interfaces/License";
import { ApiContext } from "services/ApiContext";

export const useLicensesQueries = (): UseQueryResult<Konzession[], AxiosError> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);

  const GetLicenses = async (): Promise<Konzession[]> => {
    const response = await api.get<Konzession[]>("/konzessionen");
    log.trace({ obj: response.data }, "result from get licenses");
    return response.data;
  };
  return useQuery<Konzession[], AxiosError>({
    queryKey: "Licenses",
    queryFn: GetLicenses,
    onError: (err) => logAxiosError("Error loading Konzessionen", "Fehler beim Laden der Konzessionen", err),
  });
};

export const usePossibleLicensesQueries = (): UseQueryResult<MoeglicheKonzession[], AxiosError> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);

  const GetPossibleLicenses = async (): Promise<MoeglicheKonzession[]> => {
    const response = await api.get<MoeglicheKonzession[]>("/konzessionen/moeglich");
    log.trace({ obj: response.data }, "result from get possible licenses");
    return response.data;
  };
  return useQuery<MoeglicheKonzession[], AxiosError>({
    queryKey: "PossibleLicenses",
    queryFn: GetPossibleLicenses,
    onError: (err) =>
      logAxiosError("Error loading mögliche Konzessionen", "Fehler beim Laden der möglichen Konzessionen", err),
  });
};

export const useGetAvailableLicenses = (): MoeglicheKonzession[] => {
  const [availableLicenses, setAvailableLicenses] = useState<MoeglicheKonzession[]>([]);
  const { data: possibleLicenses } = usePossibleLicensesQueries();
  const { data: existingLicenses } = useLicensesQueries();

  useEffect(() => {
    if (possibleLicenses && existingLicenses) {
      log.debug({ obj: possibleLicenses }, "got possible-licenses");
      log.debug({ obj: existingLicenses }, "got existing licenses");
      log.debug("will remove all existing licenses from possible licenses");

      const available = possibleLicenses.filter(
        (possible) => existingLicenses.findIndex((existing) => existing.sparte === possible.sparteName) === -1
      );
      log.debug({ obj: available }, "available licenses");
      setAvailableLicenses(available);
    }
  }, [possibleLicenses, existingLicenses]);

  return availableLicenses;
};
