import React, { ReactNode, useContext } from "react";

import { Footer, Header } from "@gelsenwasser/react";

import gw_logo from "../assets/logo_installateurverzeichnis_weiss.png";
import { AuthContext } from "services/authentication";
import UserSettings from "./UserSettings";

interface Props {
  children: ReactNode;
}

export const Layout: React.FC<Props> = ({ children }: Props) => {
  const authContext = useContext(AuthContext);

  return (
    <>
      <Header
        logoSrc={gw_logo}
        noWhiteBackground
        title={
          <>
            <strong>Installateur</strong><span className="font-weight-light">Verzeichnis</span>
          </>
        }
      >
        {authContext.isAuthenticated() && (
          <>
            <UserSettings />
          </>
        )}
      </Header>

      <main className="d-flex flex-column flex-fill outermain bg-gw-light">{children}</main>

      <Footer year={process.env.REACT_APP_YEAR} version={process.env.REACT_APP_VERSION} />
    </>
  );
};
