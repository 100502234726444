import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert, Spinner } from "react-bootstrap";
import { ActionButton } from "@gelsenwasser/react";

import { AuthConsumer, AuthContext } from "./AuthProvider";
import { log } from "common/Logger";

const aadErrRegex = /AADB2C[\d]{5,5}:(.*)$/gm;

export const LogInCallback: React.VFC = () => {
  // hooks
  const auth = useContext(AuthContext);
  const history = useHistory();

  // states
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Es ist ein Fehler bei der Anmeldung passiert!");

  // parameter
  // queries und mutationen
  // effekte
  useEffect(() => {
    auth.signinRedirectCallback().catch((reason) => {
      log.error({ obj: reason }, "Error while processing callback from login-redirect");
      const match = aadErrRegex.exec(reason);
      if (match !== null && match.length === 2) {
        setErrorMessage(match[1].trim());
      }
      setIsError(true);
    });
  }, []);

  // daten
  // handler
  const handleGotoStart = () => {
    history.push("/");
  };

  if (isError)
    return (
      <div className="d-flex flex-column align-items-center justify-content-center h-100">
        <Alert variant="danger">{errorMessage}</Alert>
        <ActionButton onClick={handleGotoStart} caption="Zurück zur Startseite" />
      </div>
    );
  else
    return (
      <div className="d-flex align-items-center justify-content-center h-100">
        <Spinner animation="border" role="status" variant="primary" />
        &nbsp;Sie wurden angemeldet, verarbeite Anmelde-Daten ...
      </div>
    );
};

export const LogInSilentCallback: React.VFC = () => (
  <AuthConsumer>
    {({ signinSilentCallback }) => {
      signinSilentCallback();
      return null;
    }}
  </AuthConsumer>
);
