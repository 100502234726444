import React, { useState } from "react";
import { useCompaniesQueries } from "../applications/applicationsQueries";
import { log } from "../../common/Logger";
import {
  AntragsTyp,
  EditGastAntrag,
  emptyInstallationsUnternehmen,
  gastAntragSchema,
} from "../../interfaces/Application";
import { FormikHelpers, Formik, Form as FormikForm } from "formik";
import { useHistory } from "react-router-dom";
import { useUpdateGastMainDataApplication } from "../applications/applicationMutations";
import { ContentBox, ActionButton, useNotification } from "@gelsenwasser/react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import SimpleBreadcrumb from "../../components/SimpleBreadcrumb";
import { ModalLoader } from "../../components/ZahnradLoader";
import MainDataGuest from "./MainDataGuest";
import { useEmployeesQueries } from "domain/employees/employeesQueries";

const EditGuestMainData: React.VFC = () => {
  // hooks
  const history = useHistory();
  const { addNotification } = useNotification();

  // states
  const [showLoader, setShowLoader] = useState(false);

  // parameter
  // queries und mutationen
  const { data: companies } = useCompaniesQueries();
  const { data: employees } = useEmployeesQueries();
  const { mutate: updateGastData } = useUpdateGastMainDataApplication();
  // effekte
  // daten
  const pageTitle = "Änderung Stammdaten";
  const firstCompany = companies?.[0];
  const firstEmployee = employees?.[0];
  const initialValues: EditGastAntrag = firstCompany
    ? {
        ...firstCompany,
        installationsUnternehmenId: firstCompany.id,
        antragstyp: AntragsTyp.ERWEITERUNG,
        sparte: firstCompany.sparten?.[0].toString() ?? "",
        vorname: firstEmployee?.vorname ?? "",
        nachname: firstEmployee?.nachname ?? "",
        ausweisGueltigkeit: undefined,
      }
    : {
        ...emptyInstallationsUnternehmen,
        installationsUnternehmenId: "",
        antragstyp: AntragsTyp.ERWEITERUNG,
        ausweisGueltigkeit: undefined,
      };

  // handler
  const handlePreviousStep = () => {
    history.push("/");
  };

  const handleSubmit = (values: EditGastAntrag, actions: FormikHelpers<EditGastAntrag>) => {
    log.debug("submitting gast stammdaten change");
    setShowLoader(true);
    updateGastData(values, {
      onSuccess: (data) => {
        log.debug({ obj: data }, `Stammdaten aktualisiert`);
        addNotification("Stammdaten wurden aktualisiert", "Hinweis");
        history.push("/");
      },
      onError: (err) => {
        log.error({ obj: err }, `Fehler bei Gastantrag Stammdaten Aktualisierung`);
      },
      onSettled: () => {
        setShowLoader(false);
        actions.setSubmitting(false);
      },
    });
  };

  return (
    <>
      <Container>
        <SimpleBreadcrumb subPageTitle={pageTitle} />
      </Container>
      <Container>
        <ContentBox title={pageTitle}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={gastAntragSchema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit }): JSX.Element => {
              return (
                <FormikForm onSubmit={handleSubmit}>
                  <MainDataGuest disableEditCompanyName formMode="register" />
                  <Row>
                    <Col>
                      <ActionButton caption="Zurück" icon={BsArrowLeft} onClick={handlePreviousStep} />
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <Button type="submit">Speichern</Button>
                    </Col>
                  </Row>
                </FormikForm>
              );
            }}
          </Formik>
        </ContentBox>
      </Container>
      <ModalLoader show={showLoader}>{pageTitle} wird gespeichert...</ModalLoader>
    </>
  );
};

export default EditGuestMainData;
