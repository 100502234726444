export interface Page {
  currentPage: string;
  openingPage: string;
}

export const Pages: Page[] = [
  {
    currentPage: "default",
    openingPage: "https://www.gelsenwasser.de/marktpartner",
  },
  {
    currentPage: "gelsenwasser.de",
    openingPage: "https://www.gelsenwasser.de/marktpartner",
  },
  {
    currentPage: "gemeindewerke-huenxe.de",
    openingPage: "https://www.gemeindewerke-huenxe.de/wasser/hausinstallation/installateure/",
  },
  {
    currentPage: "gw-energienetze.de",
    openingPage: "https://www.gw-energienetze.de/index.php?id=installateurverzeichnis",
  },
  {
    currentPage: "gwh-hoexter.de",
    openingPage: "https://www.gwh-hoexter.de/wasser/hausinstallation/installateure/",
  },
  {
    currentPage: "stadtwerke-kalkar.de",
    openingPage: "https://www.stadtwerke-kalkar.de/wasser/hausinstallation/installateure/",
  },
  {
    currentPage: "stadtwerke-voerde.de",
    openingPage: "https://www.stadtwerke-voerde.de/wasser/hausinstallation/installateure/",
  },
  {
    currentPage: "vgw-gmbh.de",
    openingPage: "https://www.vgw-gmbh.de/wasser/hausinstallation/installateure/",
  },
];

export const determineOpeningPage = (currentPage: string) : string => {
  const foundPages = Pages.filter((p) => currentPage.indexOf(p.currentPage) > -1);
  if (foundPages?.length) return foundPages[0].openingPage;
  return Pages[0].openingPage;
};
