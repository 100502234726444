import React, { useState, useEffect } from "react";

import SimpleBreadcrumb from "components/SimpleBreadcrumb";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import { ContentBox, FormSection } from "@gelsenwasser/react";
import { Formik, Form as FormikForm } from "formik";
import { useUpdateMainData } from "domain/licenses/licensesMutations";
import { useCompaniesQueries, useGemeindenQueries } from "domain/applications/applicationsQueries";
import { useHistory } from "react-router-dom";
import { ModalLoader } from "components/ZahnradLoader";
import { log } from "common/Logger";
import { emptyInstallationsUnternehmen, InstallationsUnternehmen } from "interfaces/Application";
import BasicCompanyData from "./BasicCompanyData";
import { useLicensesQueries } from "../licenses/licensesQueries";
import AlertTriangle from "components/AlertTriangle";

const Edit: React.VFC = () => {
  // hooks
  const history = useHistory();

  // states
  const [loaderMessage, setLoaderMessage] = useState("Daten werden geladen");

  // parameter
  // queries und mutationen
  const { mutate: updateMainData } = useUpdateMainData();
  const { data: licenses } = useLicensesQueries();
  const { data: gemeinden } = useGemeindenQueries();
  const { data: companies } = useCompaniesQueries();

  // effekte
  useEffect(() => {
    log.debug({ obj: companies?.[0] }, "result companies in effect");
    if (companies) {
      log.debug({ obj: companies[0] }, "Setting companies");
      setLoaderMessage("");
    }
  }, [companies]);

  useEffect;

  // daten
  const showLoader = loaderMessage !== "";
  const initialValue = companies ? companies[0] : emptyInstallationsUnternehmen;

  // handler
  const handleUpdateMainData = (values: InstallationsUnternehmen) => {
    setLoaderMessage("Stammdaten werden gespeichert");
    log.debug({ obj: values }, "Updating stammdaten");
    updateMainData(values, {
      onSuccess: (data) => {
        log.debug({ obj: data }, "updated maindata");
        history.push("/");
      },
      onError: (err) => {
        log.error(`Error while updating main data: ${err.response?.statusText}`);
      },
      onSettled: () => {
        setLoaderMessage("");
      },
    });
  };

  return (
    <>
      <Container>
        <SimpleBreadcrumb subPageTitle="Änderung Stammdaten" />
      </Container>
      <Container>
        <ModalLoader show={showLoader}>{loaderMessage}...</ModalLoader>
        <Formik initialValues={initialValue} onSubmit={handleUpdateMainData} enableReinitialize>
          {({ handleSubmit, setFieldValue }): JSX.Element => {
            useEffect(() => {
              if (companies && gemeinden && companies.length > 0) {
                const foundGemeinde = gemeinden?.find((item) => item.id == companies[0].gemeindeId);
                if (foundGemeinde) {
                  setFieldValue("ort", foundGemeinde.gemeindename);
                }
              }
            }, [gemeinden, companies]);
            return (
              <ContentBox title="Änderung">
                <FormikForm onSubmit={handleSubmit}>
                  <FormSection title="Stammdaten">
                    <BasicCompanyData />
                  </FormSection>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        type="submit"
                        className="mr-3"
                        onSubmit={() => {
                          handleSubmit();
                        }}
                      >
                        Speichern
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          history.push("/");
                        }}
                      >
                        Abbrechen
                      </Button>
                    </Col>
                  </Row>
                  {licenses && licenses.length > 0 && (
                    <Row>
                      <Col>
                        <Alert variant="info">
                          <AlertTriangle color="#37AFB9" />
                          <p>Wenden Sie sich bei Namensänderungen der Firma in Ihren Ansprechpartner:</p>
                          {licenses
                            ?.filter((license, index) => {
                              return (
                                licenses.findIndex((lic) => lic.ansprechpartnerid === license.ansprechpartnerid) ===
                                index
                              );
                            })
                            .map((lic) => {
                              return (
                                <p key={lic.id}>
                                  {lic.sparte}:{" "}
                                  <a href={`mailto:${lic.email}`}>
                                    {lic.vorname} {lic.nachnname}
                                  </a>
                                  {lic.telefon && (
                                    <span>
                                      {" "}
                                      - Telefon: <a href={`tel:${lic.telefon}`}>{lic.telefon}</a>
                                    </span>
                                  )}
                                  <span>
                                    {" - E-Mail: "}
                                    <a href={`mailto:${lic.email}`}>{lic.email}</a>
                                  </span>
                                </p>
                              );
                            })}
                        </Alert>
                      </Col>
                    </Row>
                  )}
                </FormikForm>
              </ContentBox>
            );
          }}
        </Formik>
      </Container>
    </>
  );
};

export default Edit;

