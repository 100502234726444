import React, { useMemo } from "react";

import { Tile } from "@gelsenwasser/react";
import { Container, Row } from "react-bootstrap";
import { useGetAvailableLicenses, useLicensesQueries } from "../licenses/licensesQueries";

import FachkraftIcon from "./Fachkraft.svg";
import KonzessionIcon from "./Konzession.svg";
import StammdatenIcon from "./Stammdaten.svg";
import { useCompaniesQueries } from "domain/applications/applicationsQueries";
import { InstallationsUnternehmenTyp } from "interfaces/Application";

interface FormCardsElementProps {
  isGuest?: boolean;
}

interface FormCardProps {
  title: string;
  imgUrl?: string;
  toPath: string;
  visible?: boolean;
}

const FormCards: React.VFC<FormCardsElementProps> = ({ isGuest }) => {
  const availableLicenses = useGetAvailableLicenses();
  const { data: companies } = useCompaniesQueries();
  const { data: licenses } = useLicensesQueries();

  const formCardProps: FormCardProps[] = [
    {
      title: "Änderung Fachkraft",
      toPath: "/employees",
      imgUrl: FachkraftIcon,
      visible: !isGuest,
    },
    {
      title: "Ausweisdaten aktualisieren",
      toPath: "/editpass",
      imgUrl: FachkraftIcon,
      visible: isGuest,
    },
    {
      title: "Erweiterung Konzession",
      toPath: "/license",
      imgUrl: KonzessionIcon,
      visible: !isGuest,
    },
    {
      title: "Änderung Stammdaten",
      toPath: isGuest ? "/editguestdata" : "/editmaindata",
      imgUrl: StammdatenIcon,
      visible: true,
    },
  ];

  const formCards = useMemo(
    () =>
      formCardProps
        // entfernen von Karten für /license, wenn ein Unternehmen in Prüfung ist oder wenn es keine Konzessionen hat
        .filter((fcp) => {
          const hasLicenses = availableLicenses.length > 1;
          const isInCheckState = companies && companies.length > 0 && companies[0].typId === InstallationsUnternehmenTyp.INPRUEFUNG;
          const isLicensePath = fcp.toPath === "/license";

          return isLicensePath ? hasLicenses || !isInCheckState : true;
        })
        // entferne alle nicht sichtbaren Karten
        .filter((fcp) => fcp.visible)
        .map((fcp, idx) =>
          fcp.title === "Änderung Fachkraft" && licenses?.length === 0 ? (
            <></>
          ) : (
            <Tile
              key={`fc_${idx}`}
              title={fcp.title}
              toPath={fcp.toPath}
              iconPath={fcp.imgUrl ?? "https://via.placeholder.com/60"}
            />
          )
        ),
    [availableLicenses, companies, isGuest]
  );

  return (
    <Container fluid className="px-0 pb-2">
      <Row className="d-flex justify-content-between">{formCards}</Row>
    </Container>
  );
};

export default FormCards;
