import React, { useEffect } from "react";

import SimpleBreadcrumb from "components/SimpleBreadcrumb";

import { Col, Row, Jumbotron, Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import ApplicationList from "./applications/ApplicationList";
import EmployeeList from "./employees/EmployeeList";
import FormCards from "./formCards/FormCards";
import LicenseList from "./licenses/LicenseList";
import { useCompaniesQueries } from "./applications/applicationsQueries";

const Dashboard: React.VFC = () => {
  // hooks
  const history = useHistory();

  // states
  // parameter
  // queries und mutationen
  const { data: companies, isFetching, isLoading } = useCompaniesQueries();

  // effekte
  useEffect(() => {
    document.title = "Installateurverzeichnis";
    if (!isFetching && !isLoading && companies && companies.length < 1) history.push("/register");
  }, [companies]);

  // daten
  const istGuest = companies ? companies[0]?.istGast : false;
  // handler
  return (
    <>
      <Container>
        <SimpleBreadcrumb />
      </Container>

      <Container>
        <Row className="mt-0">
          <Col md={8}>
            <Jumbotron className="py-4">
              <h1 className="display-4">
                <strong>Installateur</strong>Verzeichnis
              </h1>
              <p>
                Willkommen im <strong>Installateur</strong>Verzeichnis der GELSENWASSER AG und der angeschlossenen
                Unternehmen.
                <br />
                {istGuest
                  ? "Hier können Sie Ihren aktuellen Antragsstatus einsehen, Änderungen an Ihren Stammdaten vornehmen und Ihrer Fachkräfte Ausweisdaten aktualisieren."
                  : "Hier können Sie Ihren aktuellen Antragsstatus einsehen, Änderungen an Ihren Stammdaten vornehmen und die Daten Ihrer Fachkräfte bearbeiten."}
              </p>
            </Jumbotron>
            <FormCards isGuest={istGuest} />
          </Col>

          <Col md={4}>
            <LicenseList pageSize={5} />
            <ApplicationList pageSize={5} />
            <EmployeeList pageSize={5} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
