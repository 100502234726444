import { useFormikContext } from "formik";
import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { FileError } from "../../components/FileError";
import { Antrag } from "../../interfaces/Application";

const MainDataDocuments: React.VFC = () => {
  const { setFieldValue, values } = useFormikContext<Antrag>();

  const handleBetriebsHaftplichtFileSelection: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.currentTarget.files && e.currentTarget.files.length > 0)
      setFieldValue("betriebsHaftplichtDatei", e.currentTarget.files[0]);
  };

  const handleGewerbeAnmeldungFileSelection: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.currentTarget.files && e.currentTarget.files.length > 0)
      setFieldValue("gewerbeAnmeldungDatei", e.currentTarget.files[0]);
  };

  const handleHandwerksrollenEintragungFileSelection: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.currentTarget.files && e.currentTarget.files.length > 0)
      setFieldValue("handwerksrollenEintragungDatei", e.currentTarget.files[0]);
  };

  return (
    <>
      <Row>
        <Col>
          <Form.File
            label="Betriebshaftpflicht"
            onChange={handleBetriebsHaftplichtFileSelection}
            data-browse="Datei auswählen"
          />
          <FileError errorFieldName="betriebsHaftplichtDatei" />
        </Col>
      </Row>
      <FileNameLabel datei={values.betriebsHaftplichtDatei} />
      <Row>
        <Col>
          <Form.File
            label="Gewerbeanmeldung"
            onChange={handleGewerbeAnmeldungFileSelection}
            data-browse="Datei auswählen"
          />
          <FileError errorFieldName="gewerbeAnmeldungDatei" />
        </Col>
      </Row>
      <FileNameLabel datei={values.gewerbeAnmeldungDatei} />
      <Row>
        <Col>
          <Form.File
            label="Handwerksrolleneintragung"
            onChange={handleHandwerksrollenEintragungFileSelection}
            data-browse="Datei auswählen"
          />
          <FileError errorFieldName="handwerksrollenEintragungDatei" />
        </Col>
      </Row>
      <FileNameLabel datei={values.handwerksrollenEintragungDatei} />
    </>
  );
};

type FileNameLabelProps = {
  datei?: "" | File;
};

const FileNameLabel: React.VFC<FileNameLabelProps> = ({ datei }) => {
  return (
    <>
      {typeof datei !== "string" && datei !== undefined && (
        <Row>
          <Col>
            <span>
              Gelieferte Datei: <strong>&apos;{datei.name}&apos;</strong>
            </span>
          </Col>
        </Row>
      )}
    </>
  );
};

export default MainDataDocuments;
