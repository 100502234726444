import React, { useEffect, useState } from "react";

import SimpleBreadcrumb from "components/SimpleBreadcrumb";
import { Alert, Container } from "react-bootstrap";
import { ContentBox, NotificationType, useNotification } from "@gelsenwasser/react";
import { useHistory } from "react-router-dom";
import MainData from "./MainData";
import { LicenseFormMode, ViewModes } from "interfaces/Application";
import Employees from "./Employees";
import { ModalLoader } from "components/ZahnradLoader";
import { useLicensesQueries } from "./licensesQueries";
import ModalHint from "./ModalHint";
import MainDataDocuments from "./MainDataDocuments";
import GuestData from "./MainDataGuest";
import GuestDataDocuments from "./DocumentsGuest";
import FormSelection from "./FormSelection";
import GastAntragForm from "./GastAntragForm";
import AntragForm from "./AntragForm";
import GastAntragSummary from "./GastAntragSummary";

interface CreateOrExtendLicenseProps {
  formMode: LicenseFormMode;
}

const CreateOrExtendLicense: React.VFC<CreateOrExtendLicenseProps> = ({ formMode }: CreateOrExtendLicenseProps) => {
  // hooks
  const history = useHistory();
  const { addNotification } = useNotification();

  // states
  const [viewMode, setViewMode] = useState<ViewModes>("selectform");
  const [showLoader, setShowLoader] = useState(false);
  const [showHintOnStartup, setShowHintOnStartup] = useState(formMode === "register");

  // parameter
  // queries und mutationen
  const { data: licenses } = useLicensesQueries();

  // effekte
  useEffect(() => {
    // wenn wir im Registrierungs-Mode sind und es gibt schon Konzessionen, dann Warnung anzeigen zur Startseite umleiten
    if (formMode === "register" && licenses && licenses.length > 0 && !process.env.REACT_APP_ENABLE_ALWAYS_LICENSE) {
      addNotification("Es sind bereits Konzessionen vorhanden", "Registrierung nicht möglich", NotificationType.Alert);
      history.push("/");
    }
  }, [licenses]);

  // daten
  const getContent = (): JSX.Element => {
    switch (viewMode) {
      case "selectform":
        return <FormSelection setViewMode={setViewMode} />;
      case "guestdata":
        return <GuestData formMode={formMode} />;
      case "guestdatadocuments":
        return <GuestDataDocuments />;
      case "guestsummary":
        return <GastAntragSummary />;
      case "maindata":
        return <MainData formMode={formMode} />;
      case "documents":
        return <MainDataDocuments />;
      case "employees":
        return <Employees />;
      default:
        return <></>;
    }
  };
  const content = getContent();
  const pageTitle = formMode === "register" ? "Registrierung" : "Erweiterung Konzession";

  // handler
  const handlePreviousStep = () => {
    switch (viewMode) {
      case "guestdata":
        history.push("/");
        break;
      case "guestdatadocuments":
        setViewMode("guestdata");
        break;
      case "guestsummary":
        setViewMode("guestdatadocuments");
        break;
      case "maindata":
        history.push("/");
        break;
      case "documents":
        setViewMode("maindata");
        break;
      case "employees":
        setViewMode("documents");
        break;
    }
  };

  const handleHintClose = () => {
    if (showHintOnStartup) setShowHintOnStartup(false);
  };
  const showDefaultAntrag = viewMode !== "guestdata" && viewMode !== "guestdatadocuments" && viewMode !== "guestsummary";
  const showGastAntrag = viewMode === "guestdata" || viewMode === "guestdatadocuments" || viewMode === "guestsummary";

  return (
    <>
      <Container>
        <SimpleBreadcrumb subPageTitle={pageTitle} />
      </Container>
      <Container>
        <ContentBox title={pageTitle}>
          <ModalHint showDialog={showHintOnStartup} onClose={handleHintClose} />

          {formMode === "extend" && (
            <Alert variant="warning">
              Bei der Erweiterung der Konzession wird unter Umständen ein Vertrag mit einem anderen Unternehmen
              geschlossen.
            </Alert>
          )}

          {showDefaultAntrag && (
            <AntragForm
              formMode={formMode}
              viewMode={viewMode}
              setViewMode={setViewMode}
              setShowLoader={setShowLoader}
              handlePreviousStep={handlePreviousStep}
              pageTitle={pageTitle}
            >
              {content}
            </AntragForm>
          )}
          {showGastAntrag && (
            <GastAntragForm
              formMode={formMode}
              viewMode={viewMode}
              setViewMode={setViewMode}
              setShowLoader={setShowLoader}
              handlePreviousStep={handlePreviousStep}
            >
              {content}
            </GastAntragForm>
          )}
        </ContentBox>
      </Container>
      <ModalLoader show={showLoader}>{pageTitle} wird gespeichert...</ModalLoader>
    </>
  );
};

export default CreateOrExtendLicense;

