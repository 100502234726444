import React, { useContext, useEffect, useState } from "react";

import { AuthContext } from "../services/authentication";

import Dashboard from "./Dashboard";

import Splash from "./Splash";
import { log } from "common/Logger";
import CheckLogin from "./CheckLogin";

export const Home: React.FC = () => {
  // hooks
  const authContext = useContext(AuthContext);
  const isAuthenticated = authContext.isAuthenticated();

  // states
  const [isInit, setIsInit] = useState(!isAuthenticated);

  // parameter
  // queries und mutationen
  // effekte
  useEffect(() => {
    if (!isAuthenticated) {
      log.debug("not authenticated, trying to get user-details");
      authContext.getUser().then((data) => {
        log.debug({ obj: data }, "got user-details (might be null, if not yet logged in)");
        // beenden der init-phase
        setIsInit(false);
      });
    }
  }, [authContext, isAuthenticated]);

  // daten
  // handler

  // wenn init-phase, dann Checklogin anzeigen, wenn nicht authentifiziert den splash-screen ansonste das Dashboard
  if (isInit) return <CheckLogin />;
  if (!isAuthenticated) return <Splash />;
  else return <Dashboard />;
};
