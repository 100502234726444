import React, { useContext } from "react";
import { Route, RouteProps } from "react-router-dom";
import { AuthContext } from "../services/authentication";

const ProtectedRoute: React.FC<RouteProps> = (routeProps) => {
  const authContext = useContext(AuthContext);
  const isAuthenticated = authContext.isAuthenticated();
  if (isAuthenticated) {
    return <Route {...routeProps} />;
  } else {
    authContext.signinRedirect();
    return null;
  }
};

export default ProtectedRoute;
